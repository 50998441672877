import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ErrorMessage, ErrorBoundary, HeaderCDC, Footer } from '@/components'
import { useSearchParams } from '@/hooks'

import { MasterButtonBar, LayoutButtons } from './components'
import { Helmet } from 'react-helmet'

import { useSelectedTab } from '@/hooks'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: 20
  },
  container: {
    flexGrow: 1,
    height: '100%'
  },
  tabsContainer: {
    margin: '2em 0 1em'
  }
})

/**
 * The layout used for showing search results, either for state or national
 * @component
 */
export default function IssueMonitorLayout({ children }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const { startDate, endDate } = searchParams
  const hasDateParams = () => startDate.length > 0 && endDate.length > 0
  const [selectedTab] = useSelectedTab()

  return (
    <>
      <Helmet
        title={selectedTab === 0 ? 'National Legislation' : 'State Legislation'}
        meta={[
          { charset: 'utf-8' },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }
        ]}
      />
      <HeaderCDC />
      <ErrorBoundary>
        <div className={classes.root}>
          {!hasDateParams() && <ErrorMessage />}
          {hasDateParams() && (
            <Container className={classes.container} maxWidth={false}>
              <div className={classes.cardContainer}>
                <LayoutButtons />
                <MasterButtonBar />
                {children}
              </div>
            </Container>
          )}
        </div>
        <Footer />
      </ErrorBoundary>
    </>
  )
}
