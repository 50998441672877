import React from 'react'
import { Typography } from '@material-ui/core'
import { BackToSearch } from '../'

/**
 * Error Boundary use to wrap both the State
 * and National tabs. If there is an errorm,
 * send user with the message below.
 * @component
 */
class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    if (error) return { hasError: true, error }
    return false
  }

  componentDidCatch(error) {
    if (error) return { hasError: true, error }
    return false
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white'
          }}
        >
          <Typography variant='h4'>
            Something went wrong. Please try again in five minutes. If the issue persists, contact IT help for support.
          </Typography>
          <div>
            <BackToSearch />
          </div>
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
