import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { amber, green, orange, red, teal } from '@material-ui/core/colors'
import { colors } from './colors'

export const theme = {
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  typography: {
    h1: {
      fontSize: '2rem'
    },
    h2: {
      fontSize: '1.8rem'
    },
    h3: {
      fontSize: '1.6rem'
    },
    h4: {
      fontSize: '1.4rem'
    },
    h5: {
      fontSize: '1.3rem'
    },
    h6: {
      fontSize: '1.2rem'
    }
  },
  palette: {
    primary: {
      main: 'rgb(0,135,255)',
      contrastText: 'rgb(0,0,0)'
    },
    secondary: {
      main: '#fff',
      contrastText: '#fff'
    },
    error: {
      main: colors.error
    },
    background: {
      paper: '#3B3B4B',
      default: '#292935'
    },
    footer: {
      main: '#292935',
      disclaimer: '#F5F5F5',
      icons: '#D8E2EF',
      black: '#000',
      white: '#FFF'
    },
    text: {
      primary: '#fff',
      icon: '#fff'
    },
    risk: {
      low: green[500],
      lowModerate: amber[500],
      moderateHigh: orange[500],
      high: red[500]
    }
  },
  statistic: {
    value: {
      fontSize: '3rem',
      lineHeight: '3rem',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    label: {
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'uppercase'
    }
  },
  progressbar: {
    root: {
      width: '43%',
      height: 6,
      backgroundColor: 'transparent'
    },
    nationalBar: {
      backgroundColor: teal.A700
    },
    stateBar: {
      backgroundColor: '#f5ba98'
    }
  },
  datafilter: {
    color: 'white',
    fontSize: '1.1em',
    letterSpacing: '0.1em',
    textAlign: 'left'
  },
  kpiStatistic: {
    fontSize: '4.5rem',
    textAlign: 'center',
    fontWeight: 300,
    letterSpacing: 0
  },
  kpiSubStatistic: {
    fontSize: '3.5rem',
    textAlign: 'center',
    fontWeight: 300,
    letterSpacing: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  kpiTitle: {
    color: 'white',
    fontSize: '1rem',
    letterSpacing: '0.2em',
    textTransform: 'Uppercase',
    marginBottom: '.5rem',
    fontWeight: 300,
    textAlign: 'center'
  },
  kpidescription: {
    color: 'white',
    textAlign: 'left',
    fontSize: '1rem'
  },
  progressbartitle: {
    color: 'white',
    fontSize: '1rem',
    fontStyle: 'italic',
    letterSpacing: '0.2em',
    textTransform: 'Uppercase',
    maxWidth: '83%',
    wordBreak: 'break-word'
  },
  avatartooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 1,
    fontSize: 7
  },
  link: {
    color: 'white',
    textTransform: 'none',
    textDecorationLine: 'underline'
  },
  tooltiplink: {
    color: 'white',
    textTransform: 'none',
    textDecorationLine: 'underline'
  },
  dataTableText: {
    color: 'white',
    textTransform: 'none',
    focusVisible: 'true'
  },
  supportersTooltip: {
    backgroundColor: 'rgba(34,34,44,0.95)',
    boxShadow: '5px 4px 4.5px rgb(64,64,64,0.5)'
  },
  tooltipdescription: {
    color: 'white',
    textAlign: 'left',
    fontSize: '0.9rem'
  },
  tooltipArrow: {
    color: 'rgba(34,34,44, 0.90)',
    boxShadow: '5px 4px 4.5px rgb(64,64,64,0.5)',
    fontSize: 'small'
  },
  stateKpiDescription: {
    color: 'white',
    textAlign: 'center',
    fontSize: '1rem',
    margin: 'auto 3em auto 3em'
  },
  robotoLight: 300,
  roboto: 400,
  stateFeed: {
    overflow: 'auto',
    opacity: '85%',
    borderRadius: '5px',
    backgroundColor: 'rgb(24,23,23)',
    borderWidth: 'thin',
    borderStyle: 'groove',
    borderColor: '#FFFFFF',
    marginLeft: '2.3%',
    marginRight: '1.5%',
    marginBottom: '1.5%'
  },
  primaryFeedItem: {
    color: 'white',
    fontSize: '0.8em'
  },
  secondaryFeedItem: {
    color: 'white',
    fontSize: '0.85em',
    paddingTop: '5px'
  },
  sideBarContainer: {
    backgroundColor: 'rgb(24,23,23)',
    overflow: 'hidden',
    marginLeft: '2%',
    opacity: '90%',
    minHeight: '100%'
  },
  infoButtonBox: {
    backgroundColor: '#ffffff',
    width: '22px',
    height: '22px',
    cursor: 'pointer'
  }
}

const muiTheme = createTheme(theme)

muiTheme.overrides = {
  MuiButton: {
    root: {
      '&$focusVisible': {
        outline: '2px solid #ffffff'
      }
    }
  },
  MuiButtonBase: {
    root: {
      '&$focusVisible': {
        outline: '2px solid #ffffff'
      }
    }
  },
  MuiIconButton: {
    root: {
      '&$focusVisible': {
        outline: '2px solid #ffffff'
      }
    }
  },
  MuiList: {
    root: {
      backgroundColor: '#292935'
    }
  },
  MuiOutlinedInput: {
    root: {
      '&:hover $notchedOutline': {
        borderColor: 'none'
      }
    }
  },
  MuiTab: {
    root: {
      padding: 0,
      margin: '6px 12px',
      '&$textColorPrimary': {
        '&$selected': {
          color: 'white'
        }
      }
    },
    focusVisible: {
      outline: '2px solid #ffffff'
    }
  }
}

// Use helper to make sure font sizes are responsive
const responsiveTheme = responsiveFontSizes(muiTheme)

export default responsiveTheme
