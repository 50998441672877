import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Sidebar, Menu } from 'semantic-ui-react'
import 'semantic-ui-css/components/sidebar.min.css'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Grid } from '@material-ui/core'
import { CellMeasurer, Grid as GridList, CellMeasurerCache } from 'react-virtualized'

import MapFeedItem from './MapFeedItem'
import { LoadingSpinner } from '@/components'

import './MapFeed.css'

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    backgroundColor: 'rgb(24,23,23)',
    marginLeft: '2%',
    overflow: 'hidden'
  },
  title: {
    color: '#fff',
    letterSpacing: '0.09em',
    fontSize: '0.9rem',
    alignItems: 'left',
    textAlign: 'left',
    marginLeft: '3%',
    textTransform: 'uppercase',
    fontWeight: 300
  },
  iconButton: {
    color: '#fff'
  },
  closeIcon: {
    fontSize: '1rem'
  },
  feed: theme.stateFeed,
  sideBarContainer: {
    ...theme.sideBarContainer,
    height: '100%'
  },
  sideBar: {
    overflowY: 'hidden !important'
  },
  emptyContainer: {
    height: '100%'
  }
}))

// This components handles showing the feed for a selected state on the map
export default function MapFeed({
  visible,
  title,
  clickedState,
  onCloseFeed,
  feed,
  showFeed,
  type,
  onFinishedClosing,
  emptyFeedText
}) {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const cellMeasurerCache = new CellMeasurerCache({
    defaultWidth: 400,
    fixedWidth: true
  })

  useEffect(() => {
    // if the drawer is open, show the loading spinner
    if (visible) {
      setLoading(true)
    }

    // we wait until the drawer has actually finished opening
    // before we show the feed. this prevents issues that arise when
    // the feed updates half way through opening.
    if (open) {
      if (showFeed) {
        setLoading(false)
      }
    }
  }, [showFeed, visible, open])

  // handles when the drawer has finished opening
  const handleOnShow = () => {
    setLoading(true)
    setOpen(true)
  }

  // handle when the drawer has finished closing
  const handleOnHidden = () => {
    setOpen(false)
    onFinishedClosing()
  }

  // renders a row in the feed list
  const feedItemRenderer = ({ rowIndex, parent, style, key }) => {
    const item = feed[rowIndex]

    return (
      <CellMeasurer cache={cellMeasurerCache} columnIndex={0} key={key} rowIndex={rowIndex} parent={parent}>
        <MapFeedItem style={style} item={item} type={type} />
      </CellMeasurer>
    )
  }

  return (
    <Sidebar
      as={Menu}
      animation='overlay'
      width='wide'
      visible={visible}
      onShow={handleOnShow}
      onHidden={handleOnHidden}
      direction='right'
      vertical
      id='mapFeed'
    >
      <Grid container className={classes.titleContainer}>
        <Grid item xs={10} className={classes.title}>
          {clickedState} {title}
        </Grid>
        <Grid item xs>
          <IconButton className={classes.iconButton} onClick={onCloseFeed} size='small'>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Grid>
      </Grid>
      <div className={classes.sideBarContainer}>
        {loading && <LoadingSpinner />}
        {!loading && feed.length === 0 && (
          <Grid container alignItems='center' justifyContent='center' className={classes.emptyContainer}>
            {emptyFeedText}
          </Grid>
        )}
        {!loading && feed.length > 0 && (
          <GridList
            cellRenderer={feedItemRenderer}
            columnCount={1}
            autoContainerWidth
            columnWidth={330}
            height={375}
            width={330}
            rowCount={feed.length}
            rowHeight={cellMeasurerCache.rowHeight}
            className={classes.feed}
          />
        )}
      </div>
    </Sidebar>
  )
}
