import { Paper, Typography, List, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { FeedType } from '@/utils/constants'
import SupporterFeedItem from './SupporterFeedItem'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#292935',
    width: '95%',
    height: 300
  },
  tileGridList: {
    alignItems: 'left',
    textAlign: 'left',
    margintop: '0.6rem',
    justifyContent: 'left'
  },
  subtitle1variant: {
    color: 'white',
    position: 'relative',
    top: '45%',
    width: '20vw',
    alignItems: 'center',
    left: '10%',
    fontSize: '0.9rem'
  },
  subtitle2variant: {
    color: 'white',
    marginLeft: '1rem'
  },
  feed: {
    overflow: 'auto',
    opacity: '85%',
    borderRadius: '5px',
    width: '100%'
  },
  filtertitle: theme.datafilter,
  margintitle: {
    marginBottom: '1.2em'
  }
}))

export default function SupporterFeed({ feedData, loading, cardText }) {
  const classes = useStyles()

  let feed = feedData ? feedData.supporterBillsByOpensecretsId : []

  return (
    <>
      <Grid container>
        <Typography variant='h5' classes={{ h5: classes.filtertitle, root: classes.margintitle }}>
          LEGISLATIVE ITEMS
        </Typography>
      </Grid>
      <Paper className={classes.root} elevation={3}>
        {!feedData && !loading && (
          <Typography variant='subtitle1' classes={{ subtitle1: classes.subtitle1variant }}>
            {cardText}
          </Typography>
        )}
        {feed && (
          <List className={classes.feed}>
            {feed.map((item) => (
              <SupporterFeedItem key={item.billId} item={item} type={FeedType.NATIONAL_BILL} />
            ))}
          </List>
        )}
      </Paper>
    </>
  )
}

SupporterFeed.propTypes = {}

SupporterFeed.defaultProps = {}
