import { gql } from '@apollo/client'

const stateTableQuery = gql`
  query stateTableQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!) {
    statePolicyTableData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      billId
      billNumber
      url
      session
      lastActionDate
      description
      status
      topics
      state
      billType
      body
      statusDate
      status
    }
  }
`
export { stateTableQuery }
