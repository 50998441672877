import React from 'react'
import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { BillStatusEnum } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({ link: theme.link }))

export function MapFeedBill({ item, includeMember }) {
  const classes = useStyles()
  const { billNumber, description, url, fullName } = item

  return (
    <>
      {includeMember && <div>{fullName}</div>}
      <Link className={classes.link} href={url} target='_blank' rel='noopener'>
        {billNumber}
      </Link>
      <span style={{ color: '#A6A6A6', paddingLeft: '0.4rem' }}>
        {description.length > 400 ? `${description.substring(0, 400)}...` : description}
      </span>
    </>
  )
}

export function MapFeedBillSubInfo({ item }) {
  const { statusDate, status } = item

  return (
    <>
      {BillStatusEnum[status]} <span style={{ float: 'right' }}>{moment(statusDate).format('MM/DD/YYYY')}</span>
    </>
  )
}
