import { mergeWith, orderBy, chain, uniqBy, maxBy, minBy, max as maxIn } from 'lodash'
import { cancerTagValues } from './constants'

const burgundy = ['#fbe6c5', '#f5ba98', '#c8586c', '#70284a']

const cancerTerms = cancerTagValues.map((v) => v.toLowerCase())

/**
 * Loops through an array and gets the total of all .count values
 * @param {Array} arrayOfObj
 * @returns {Number} total
 */
const getTotal = (arrayOfObj) => arrayOfObj.reduce((total, obj) => total + obj.count, 0)

/**
 * Appends cancer to cancer related terms
 * @param {Array} tagNames
 * @returns {Array}
 */
const searchedTags = (tagNames) => {
  const terms = tagNames.map((term) =>
    term === 'cancer'
      ? 'cancer'
      : term === 'sdoh'
      ? 'Social Determinants of Health'
      : cancerTerms.includes(term.toLowerCase())
      ? `${term} cancer`
      : term
  )

  return terms
}

const splitTopState = (topStates) => {
  let states = topStates

  if (topStates.length > 3) {
    states = topStates.map((x) => x.name).slice(0, 3)
  }
  if (states.length === 1) {
    return states[0].name
  }
  if (states.length === 2) {
    return `${states[0].name} and ${states[1].name}`
  }
  if (states.length === 3) {
    return `${states[0].name}, ${states[1].name} and ${states[2].name}`
  }
}

const getFormattedNumber = (number, minimumFractionDigits, maximumFractionDigits) => {
  const formattedNumber = number.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits })
  return formattedNumber
}

const getPercentChange = (startNumber, newNumber) => {
  const difference = newNumber - startNumber
  return difference / startNumber
}

const arrayToObj = (array) => array.reduce((a, b) => ((a[b] = true), a), {})

/**
 * Determines the different ranges for displaying a legend on a map
 *
 * @param {Array} counts Array of state counts
 * @param {Number} bucketNumber Maximum number of items that can be on the legend.
 * @param {Array} colors colors to use
 */
const getRanges = (counts, bucketNumber, colors = burgundy) => {
  const uniqCounts = uniqBy(counts, 'count')
    .map((state) => state.count)
    .sort()
  const numberOfUniq = uniqCounts.length
  const maxCount = maxBy(counts, 'count')
  const minCount = minBy(counts, 'count')
  const min = minCount.count
  const max = maxCount.count
  const r = []

  if (numberOfUniq > 1) {
    // if there's only 3 uniqe numbers, we set the interval denom
    // to 2, so there can be a range.
    const intervalDenom =
      numberOfUniq < bucketNumber ? (numberOfUniq <= 3 ? maxIn([1, numberOfUniq - 1]) : numberOfUniq) : bucketNumber

    const interval = numberOfUniq <= 5 ? (max - min) / (intervalDenom * 2) : (max - min) / intervalDenom

    const rounded = Math.ceil(interval)

    let i = 0
    let val = min
    while (i < intervalDenom) {
      const lower = i === 0 ? val : val + 1
      const upper = val + rounded
      val = upper
      // last one
      if (i === intervalDenom - 1) {
        r.push({ lower, upper, value: `${lower}+`, color: colors[i] })
      } else {
        r.push({ lower, upper, value: `${lower}-${upper}`, color: colors[i] })
      }
      i++
    }

    return r
  }
  r.push({ lower: min, upper: min, value: `${min}`, color: colors[0] })

  return r
}

const groupDataByDimension = (data) => {
  const processedData = chain(data)
    .groupBy('dimension')
    .map((obj) =>
      mergeWith.apply(
        obj,
        [{}].concat(obj, (obj, src, key) => (key === 'count' ? (obj || 0) + src : undefined))
      )
    )
    .value()
  const sortedData = orderBy(processedData, ['count', 'dimension'], ['desc', 'asc'])
  return sortedData
}

const groupDataBystatus = (data) => {
  const processedData = chain(data)
    .groupBy('dimension')
    .map((obj) =>
      mergeWith.apply(
        obj,
        [{}].concat(obj, (obj, src, key) => (key === 'count' ? (obj || 0) + src : undefined))
      )
    )
    .value()
  return processedData
}

const aggregateData = (data, groupBy) =>
  chain(data)
    .groupBy(groupBy)
    .map((obj) =>
      mergeWith.apply(
        obj,
        [{}].concat(obj, (obj, src, key) => (key === 'count' ? (obj || 0) + src : undefined))
      )
    )
    .value()

// debounce a function
const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this
    const args = arguments

    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

/**
 * Helper function that gets billType property from backend
 * data and returns a string based off it
 * @function
 * @param {string} bill - String to be compared in switch statement
 */
const billSwitch = (bill) => {
  let billType
  switch (bill) {
    case 'R':
      billType = 'Resolution'
      break
    case 'CR':
      billType = 'Concurrent Resolution'
      break
    case 'JR':
      billType = 'Joint Resolution'
      break
    default:
      billType = 'Bill'
  }

  return billType
}

export {
  billSwitch,
  getTotal,
  groupDataByDimension,
  groupDataBystatus,
  searchedTags,
  getFormattedNumber,
  getPercentChange,
  splitTopState,
  arrayToObj,
  aggregateData,
  getRanges,
  debounce
}
