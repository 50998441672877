import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { uniqBy, filter } from 'lodash'
import { getYear } from 'date-fns'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSearchParams } from '@/hooks'
import { searchedTags, getPercentChange } from '@/utils/helper'
import { SingleSelect } from '@/components'

const useStyles = makeStyles((theme) => ({
  statistic: theme.kpiStatistic,
  subtitle: theme.kpiTitle,
  dataStory: theme.kpidescription
}))

/**
 * This shows the textual KPI information as well as a dropdown
 * to allow users to select different years to view different KPI
 * information
 */
export default function PolicyPercentChangeKpi({ data }) {
  const { endDate, tags } = useSearchParams()
  const tagNames = Object.keys(tags)
  const searchedTerms = searchedTags(tagNames)
  const endDateYear = getYear(new Date(endDate))

  const [fromYear, setFromYear] = useState(endDateYear - 1)
  const [toYear, setToYear] = useState(endDateYear)

  const dataYears = uniqBy(data, 'year').map((a) => a.year)

  const policyTotalsByYear = {}
  dataYears.forEach((year) => {
    const filteredYear = filter(data, (o) => o.year === year)
    const yearTotal = filteredYear.reduce((total, yearData) => total + yearData.count, 0)
    Object.assign(policyTotalsByYear, { [year]: yearTotal })
  })

  const years = dataYears.map((year, idx) => ({
    id: idx,
    value: year,
    label: year
  }))

  const fromYearTotal = policyTotalsByYear[fromYear]
  const toYearTotal = policyTotalsByYear[toYear]
  const percentChange = getPercentChange(fromYearTotal, toYearTotal)

  /**
   * basic helper for determining if there's be an increase or decrease
   */
  const hasIncreased = (percent) => Math.sign(percent) === 1

  /**
   * handles when the user changes the from date dropdown
   */
  const handleFromChange = (e) => setFromYear(e.target.value)

  /**
   * handles when the user changes the to date dropdown
   */
  const handleToChange = (e) => setToYear(e.target.value)

  const classes = useStyles()
  return (
    <>
      <Typography className={classes.statistic}>{numeral(percentChange).format('0%')}</Typography>
      <Typography
        className={classes.subtitle}
        align='center'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <span style={{ marginRight: '.8rem' }}>LEGISLATIVE ITEMS FROM</span>
        <SingleSelect
          name='selectFromYear'
          options={years.filter((d) => d.value < toYear)}
          value={fromYear}
          onChange={handleFromChange}
          ariaLabel='Select start year compare date'
        />
        <span style={{ margin: '0px 8px' }}>to</span>
        <SingleSelect
          name='selectToYear'
          options={years.filter((d) => d.value > fromYear)}
          value={toYear}
          onChange={handleToChange}
          ariaLabel='Select end year compare date'
        />
      </Typography>
      <Typography className={classes.dataStory}>
        From {fromYear} to {toYear}, there was a {numeral(Math.abs(percentChange)).format('0%')}{' '}
        {hasIncreased(percentChange) ? 'increase' : 'decrease'} in volume of legislative items across both chambers of
        Congress and all status types related to <i>{searchedTerms}</i>. There were{' '}
        {fromYearTotal === undefined ? 0 : fromYearTotal} legislative items in {fromYear} and{' '}
        {toYearTotal === undefined ? 0 : toYearTotal} legislative items in {toYear}.
      </Typography>{' '}
    </>
  )
}

PolicyPercentChangeKpi.propTypes = {
  /**
   * data that holds values for calculating percentage
   */
  data: PropTypes.array
}

PolicyPercentChangeKpi.defaultProps = {
  data: []
}
