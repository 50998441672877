import { makeStyles } from '@material-ui/core'

import cdcLogo from '@/assets/images/cdclogo.svg'

const useStyles = makeStyles(() => ({
  cdcBar: {
    background: '#ffffff',
    boxShadow: 'none',
    padding: '15px 31px',
    display: 'block'
  },
  cdcLogo: {
    height: '100%'
  }
}))

/**
 * Displays the CDC Header
 * @component
 */
export default function HeaderCDC({ hidden }) {
  const classes = useStyles()

  return (
    <div className={classes.cdcBar} role='banner' aria-hidden={hidden}>
      <a href='https://www.cdc.gov/'>
        <img className={classes.cdcLogo} src={cdcLogo} aria-label='CDC Logo' alt='CDC Logo' />
      </a>
    </div>
  )
}
