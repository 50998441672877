import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Sidebar, Segment } from 'semantic-ui-react'

import { MapBoxCard, MapFeed, DataUnavailable } from '@/components'
import { useMapFeedStatus, useSelectedTab } from '@/hooks'
import { getRanges } from '@/utils/helper'
import PolicyMapTooltip from './PolicyMapTooltip'

import 'semantic-ui-css/components/sidebar.min.css'

// This text is shown when a state is either hovered over or clicked and there
// are no items to display
const EMPTY_TEXT = 'No items'

// this is a web worker to handle filtering data when a user clicks on a state
// putting it in a web worker prevents the operation from blocking the rendering
const stateFilterWorker = new Worker(`${process.env.PUBLIC_URL || ''}/workers/filter.worker.js`)

/**
 * This is a wrapper for showing a mapbox map with a bill feed that
 * shows when the user clicks on a state
 * @component
 */
export default function PolicyMap({ counts, countType, feed, feedType, title }) {
  const emerald = ['#d3f2a3', '#97e196', '#4c9b82', '#105965']
  const [tab] = useSelectedTab()
  const [feedOpen, updateMapFeedStatus] = useMapFeedStatus()

  const [hovered, setHovered] = useState({
    hoveredFeature: null,
    x: null,
    y: null
  })

  const [clickedState, setClickedState] = useState('')
  const [billFeed, setBillFeed] = useState([])
  const [isDoneLoading, setDoneLoading] = useState(false)

  // our web worker is finished filtering the data
  useEffect(() => {
    const sortStateData = (filtered) => {
      let feed = []

      // filter the feed for the clicked state
      feed = orderBy(filtered, 'statusDate', 'desc')

      setBillFeed(feed)
      setDoneLoading(true)
    }

    stateFilterWorker.onmessage = ($event) => {
      if ($event && $event.data) {
        sortStateData($event.data)
      }
    }
  }, [stateFilterWorker, tab])

  /**
   * handles when the user hovers on the map
   * @param {Object} event
   */
  const handleOnHover = (event) => {
    const {
      features,
      srcEvent: { offsetX, offsetY }
    } = event

    // checks if the user is hovering over an actual state
    const hoveredFeature = features && features.find((f) => f.layer.id === 'state')

    setHovered({ hoveredFeature, x: offsetX, y: offsetY })
  }

  /**
   * handles when the user clicks somewhere on the map
   * @param {Object} event
   */
  const handleOnClick = (event) => {
    const { features } = event

    // check if the user actually clicked a state
    const clickedFeature = features && features.find((f) => f.layer.id === 'state')

    if (clickedFeature !== undefined) {
      // user has changed states so we need to show the loading spinner
      if (feedOpen) {
        setDoneLoading(false)
      }

      const { STUSPS, NAME } = clickedFeature.properties

      updateMapFeedStatus(true)
      setClickedState(NAME)

      stateFilterWorker.postMessage({
        msg: 'filterByState',
        items: feed,
        state: STUSPS
      })
    }
  }

  /**
   * handles when the user clicks 'x' in the map feed.
   * closes the feed, clears loading status and clicked state.
   */
  const handleCloseFeed = () => updateMapFeedStatus(false)

  /**
   * call this so the map and calling component can wait to
   * clear the contents until after the drawer has finished closing
   */
  const handleFeedFinishedClosing = () => {
    setDoneLoading(false)
    setClickedState('')
  }

  if (counts.length > 0) {
    const range = getRanges(counts, 4, emerald)
    return (
      <Sidebar.Pushable as={Segment}>
        <MapFeed
          clickedState={clickedState}
          emptyFeedText={EMPTY_TEXT}
          feed={billFeed}
          onCloseFeed={handleCloseFeed}
          onFinishedClosing={handleFeedFinishedClosing}
          title={title}
          type={feedType}
          showFeed={isDoneLoading}
          visible={feedOpen}
        />
        <Sidebar.Pusher>
          <MapBoxCard
            colorScale={emerald}
            counts={counts}
            countType={countType}
            hoveredState={hovered}
            noItemsTooltipText={EMPTY_TEXT}
            onHover={handleOnHover}
            onClick={(e) => handleOnClick(e)}
            range={range}
            tooltip={<PolicyMapTooltip />}
          />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    )
  }

  return <DataUnavailable />
}

PolicyMap.propTypes = {
  /**
   * array of counts of items
   */
  counts: PropTypes.array,
  /**
   * name of legend on map box
   */
  countType: PropTypes.string,
  /**
   * full list of policy items
   */
  feed: PropTypes.array,
  /**
   * national or state
   */
  feedType: PropTypes.number,
  /**
   * title of the bill feed
   */
  title: PropTypes.string
}

PolicyMap.defaultProps = {
  counts: [],
  countType: 'Items',
  feed: [],
  feedType: 1,
  title: 'Legislative Items'
}
