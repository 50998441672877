import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useBasePath } from '@/hooks'
import { Default, IssueMonitor } from '@/layouts'
import { Home, NationalDashboard, StateDashboard } from '@/views'

import { NotFound, RouteWrapper } from './components'

/**
 * Root router for the application.
 * @component
 */
export default function Router() {
  /* Required: base URL is required for routing when app is deployed */
  const base = useBasePath()

  return (
    <BrowserRouter>
      <Switch>
        <RouteWrapper exact path={`${base}/`} component={Home} layout={Default} />
        <RouteWrapper exact path={`${base}/national`} component={NationalDashboard} layout={IssueMonitor} />
        <RouteWrapper exact path={`${base}/state`} component={StateDashboard} layout={IssueMonitor} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}
