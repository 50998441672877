import { format } from 'date-fns'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Card, CardContent } from '@material-ui/core'

const useStyles = makeStyles({
  tooltipContainer: {
    backgroundColor: 'rgba(34,34,44,.95)',
    borderRadius: 7
  },
  tooltipTitle: {
    marginTop: '-.8em',
    marginBottom: '.9em'
  },
  statsContainer: {
    whiteSpace: 'pre',
    display: 'flex',
    alignItems: 'center'
  }
})

/**
 * Component used to display tooltips specfically for
 * the Policy Over Time Card.
 * @component
 */
export default function PolicyItemsOverTimeTooltip({ points }) {
  const classes = useStyles()
  return (
    <Card className={classes.tooltipContainer}>
      <CardContent>
        <div className={classes.tooltipTitle}>Week of {format(new Date(points[0].data.x), 'LLL dd')}</div>
        {points.map((point) => {
          const { data, serieColor, serieId } = point
          const { yFormatted } = data
          return (
            <div className={classes.statsContainer} key={serieId}>
              <div
                style={{
                  backgroundColor: serieColor,
                  height: 15,
                  width: 15,
                  borderRadius: '50%',
                  marginRight: 10,
                  marginLeft: '.4em'
                }}
              />
              <span>{serieId}</span>{' '}
              <span style={{ marginLeft: 7 }}>
                {' '}
                <strong>{yFormatted}</strong>
              </span>
            </div>
          )
        })}
      </CardContent>
    </Card>
  )
}

PolicyItemsOverTimeTooltip.propTypes = {
  /**
   * Object passed containing the data and chart propetiers.
   */
  points: PropTypes.array
}

PolicyItemsOverTimeTooltip.defaultProps = {
  points: []
}
