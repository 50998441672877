import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSenateHouseToggle } from '@/hooks'
import SingleSelect from './SingleSelect'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  toggleGroup: {
    backgroundColor: theme.palette.background.default
  },
  toggleContainer: {
    paddingTop: '2rem'
  },
  captionvariant: {
    color: 'white',
    alignItems: 'center',
    fontSize: '1rem',
    paddingRight: '0.5rem'
  }
}))

/**
 * Body Toggle component to switch between
 * House and Senate.
 *
 * @component
 */
export default function BodyToggle({ resetSelected }) {
  const classes = useStyles()
  const [senateHouseToggle, updateToggle] = useSenateHouseToggle()

  /**
   * Handle Change Function that updates senate house
   * toggle.
   *
   * @param {*} event - event object that is passed in.
   */
  const handleChange = (event) => {
    const selected = event.target.value
    if (selected !== null) {
      updateToggle(selected)
      resetSelected()
    }
  }

  const options = [
    { id: 0, label: 'House', value: 'rep' },
    { id: 1, label: 'Senate', value: 'sen' }
  ]

  return (
    <div className={classes.toggleContainer}>
      <Typography variant='caption' align='center' classes={{ caption: classes.captionvariant }}>
        Select a chamber:
      </Typography>
      <SingleSelect
        options={options}
        onChange={handleChange}
        value={senateHouseToggle}
        name='bodyToggle'
        ariaLabel='Select chamber of Congress'
      />
    </div>
  )
}

BodyToggle.propTypes = {
  /**
   * Data object being passed into toolTip
   */
  resetSelected: PropTypes.func
}

BodyToggle.defaultProps = {
  resetSelected: () => {}
}
