import { useQuery, gql } from '@apollo/client'
import { sortToggleVar } from '@/graphql/cache'

const GET_SORT_TOGGLE = gql`
  query CurrentSortValue {
    sortToggle @client
  }
`

export default () => {
  const { data } = useQuery(GET_SORT_TOGGLE)

  // update
  const update = (val) => sortToggleVar(val)

  return [data.sortToggle || 'engagements', update]
}
