import { Box, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  title: {
    fontWeight: 700
  }
})

/**
 * Reusable wrapper for a section column in the CDC footer
 * @component
 */
export default function FooterSection({ children, title }) {
  const classes = useStyles()

  return (
    <Box>
      <Typography className={classes.title} variant='body2'>
        {title}
      </Typography>
      <Box pt={2}>{children}</Box>
    </Box>
  )
}

FooterSection.propTypes = {
  /**
   * children of the footer
   */
  children: PropTypes.any,
  /**
   * Title of this footer section
   */
  title: PropTypes.string
}
