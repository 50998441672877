import { Grid } from '@material-ui/core'

/**
 * A container for showing KPI content
 * @component
 */
export default function KPI({ children }) {
  return (
    <Grid
      container
      item
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ flex: '1 1 30%', padding: '0 30px' }}
    >
      {children}
    </Grid>
  )
}
