import PropTypes from 'prop-types'
import Pluralize from 'react-pluralize'
import { MasterTabEnum } from '@/utils/constants'
import { useSelectedTab } from '@/hooks'

/**
 * Content of the tooltip that is shown when hovering over a state
 * on the map.
 * @component
 */
export default function PolicyMapTooltip({ tooltip }) {
  const [tab] = useSelectedTab()
  const { count, billCount } = tooltip

  const content =
    tab === MasterTabEnum.NationalPolicy ? (
      <>
        <Pluralize singular='member' count={count} /> of congress sponsoring{' '}
        <Pluralize singular='item' count={billCount} />
      </>
    ) : (
      <Pluralize singular='legislative item' count={count} />
    )

  return (
    <>
      {content}.
      <br />
      Click the state for more information.
    </>
  )
}

PolicyMapTooltip.propTypes = {
  /**
   * Contains the member and policy count to show in tooltip
   */
  tooltip: PropTypes.object
}

PolicyMapTooltip.defaultProps = {
  tooltip: { count: 0, billCount: 0 }
}
