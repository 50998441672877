import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'
import { SettingsOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    backgroundColor: '#2E2E3B',
    borderRadius: 4,
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: 1
    },
    fontWeight: theme.robotoLight,
    fontSize: '1rem'
  },
  option: {
    backgroundColor: theme.palette.background.default
  },
  popper: {
    backgroundColor: theme.palette.background.default
  },
  clearIndicator: {
    display: 'none'
  },
  noOptions: {
    color: '#fff',
    fontWeight: theme.default
  },
  arrow: {
    color: '#fff',
    padding: '1px',
    marginRight: '-8px'
  }
}))

/**
 * Component for autocomplete dropdown. Lists options
 * untill user selects a specific one.
 * @component
 */
export default function AutoCompleteDropdown({ onChange, options, value, style, ariaLabel }) {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('All')
  const [textValue, setTextValue] = useState('All')

  const handleChange = (e, value) => setInputValue(value)

  return (
    <Autocomplete
      aria-label={ariaLabel}
      autoSelect
      classes={{
        inputRoot: classes.inputRoot,
        option: classes.option,
        popper: classes.popper,
        clearIndicator: classes.clearIndicator,
        noOptions: classes.noOptions,
        focused: classes.muiFocused,
        popupIndicator: classes.arrow
      }}
      id='topics-filter'
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={handleChange}
      options={options}
      renderInput={(params) => (
        <TextField defaultValue={textValue} classes={{ root: classes.textFieldRoot }} {...params} variant='outlined' />
      )}
      size='small'
      style={style}
      value={value}
    />
  )
}

AutoCompleteDropdown.propTypes = {
  /**
   * Label for Aria in Drop Down
   */
  ariaLabel: PropTypes.string,
  /**
   * Callback function when menu item is selected.
   */
  onChange: PropTypes.func,
  /**
   * Options array to populate drop down.
   */
  options: PropTypes.array,
  /**
   * The input value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

AutoCompleteDropdown.defaultProps = {
  ariaLabel: '',
  onChange: () => {},
  reset: () => {},
  options: [],
  value: ''
}
