import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { useMapFeedStatus } from '@/hooks'
import { Tutorial, MobilePopup } from '@/components'
import { Typography } from '@material-ui/core'
import {
  NationalKPI,
  PolicyOverTime,
  NationalTable,
  TopicsByStatus,
  TopSupporters,
  AdvocateMap,
  PolicyProgress
} from './Cards'
import { useSearchParams } from '@/hooks'
import { SearchResultsHeader } from 'src/views'

/**
 * National Dashboard Card
 * Parent Component containing all Cards related to
 * National Policy
 * @component
 */
export default function NationalDashboard() {
  const searchParams = useSearchParams()
  const [, updateMapFeedStatus] = useMapFeedStatus()

  useEffect(() => {
    updateMapFeedStatus(false)
  }, [])

  return (
    <div id='national_dashboard' aria-label='National Dashboard' role='main' style={{ marginTop: 20 }}>
      {isMobile ? (
        <MobilePopup />
      ) : (
        <>
          <Tutorial isHomeTut={false} />
          <div>
            <Typography style={{ color: 'white' }} variant='h1'>
              National Legislation
            </Typography>
          </div>
          <div role='complementary'>
            <SearchResultsHeader searchParams={searchParams} />
          </div>
          <NationalKPI />
          <NationalTable />
          <PolicyOverTime />
          <TopicsByStatus />
          <PolicyProgress />
          <TopSupporters />
          <AdvocateMap />
        </>
      )}
    </div>
  )
}
