import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSearchParams } from '@/hooks'
import { searchedTags, splitTopState, getFormattedNumber } from '@/utils/helper'
import { DataUnavailable } from '@/components'

const useStyles = makeStyles((theme) => ({
  mapKpiTitle: theme.kpiTitle,
  dataStory: theme.kpidescription,
  Kpi: theme.kpiStatistic
}))

export default function StateKpiHeatMap({ policyCount, totalYears, stateCount, topStateAllyears, topStateThisYear }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const tagNames = Object.keys(searchParams.tags)
  const searchedTerms = searchedTags(tagNames)
  const topStateAllYearNames = splitTopState(topStateAllyears)
  const topStateThisYearNames = splitTopState(topStateThisYear)

  return policyCount === 0 ? (
    <DataUnavailable />
  ) : (
    <>
      <Typography className={classes.Kpi}>{getFormattedNumber(policyCount, 0, 1)}</Typography>
      <Typography className={classes.mapKpiTitle} align='center'>
        {policyCount === 1 ? 'LEGISLATIVE ITEM' : 'LEGISLATIVE ITEMS'}
      </Typography>
      <Typography className={classes.dataStory} variant='caption'>
        During the past {totalYears} years, there were {getFormattedNumber(policyCount, 0, 1)} legislative items across{' '}
        {stateCount} states and Washington DC related to <i>{searchedTerms}</i>. {topStateAllYearNames}{' '}
        {topStateAllyears.length === 1 ? 'has' : 'have'} the most legislative items across all years and{' '}
        {topStateThisYearNames} {topStateThisYear.length === 1 ? 'has' : 'have'} the most legislative items so far this
        year.
      </Typography>
    </>
  )
}
