import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  card: {
    color: 'black'
  },

  dialogue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

/**
 * Component for popup to display if the user is visiting from
 * a mobile device.
 * @component
 */
export default function MobilePopup() {
  const classes = useStyles()

  return (
    <div classes={classes.dialogue} id='testingDialogueBox'>
      <Dialog
        open={true}
        PaperProps={{
          style: {
            backgroundColor: 'white',
            opacity: '100%',
            width: '50%',
            right: '50px',
            bottom: '100px'
          }
        }}
        classes={{ root: classes.card }}
        aria-labelledby='is-mobile-pop-up'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle className={classes.card}>{'On a mobile device?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            We have detected that you are visiting this site using a mobile device. To utilize full functionality of the
            site, you must use a desktop browser.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
