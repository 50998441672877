import { makeStyles } from '@material-ui/styles'
import { ListItem, ListItemText } from '@material-ui/core'

import { MapFeedBillSubInfo, MapFeedBill } from './MapFeedBill'
import { FeedType } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({
  primaryFeedItem: theme.primaryFeedItem,
  secondaryFeedItem: theme.secondaryFeedItem
}))

export default function MapFeedItem({ item, style, type }) {
  const classes = useStyles()

  // determine which feed components to use based on the type of feed: bill
  const PrimaryListComponent = MapFeedBill
  const SubListComponent = MapFeedBillSubInfo

  // if it's a national bill, we include the name of the member of congress sponsoring it
  const includeMember = type === FeedType.NATIONAL_BILL

  return (
    <ListItem style={style}>
      <ListItemText
        classes={{ primary: classes.primaryFeedItem, secondary: classes.secondaryFeedItem }}
        primary={<PrimaryListComponent item={item} includeMember={includeMember} />}
        secondary={<SubListComponent item={item} />}
      />
    </ListItem>
  )
}
