import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  h5variant: {
    color: 'white'
  }
}))

/**
 * Shows a message 'Data not available for Selection'
 * @component
 */
export default function DataUnavailable() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography classes={{ h5: classes.h5variant }} variant='h5'>
        Data not available for selection.
      </Typography>
    </div>
  )
}
