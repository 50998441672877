import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Grid, makeStyles, Link, TableRow, TableCell } from '@material-ui/core'

import { Card, DataTableLayout, Topics, LoadingSpinner, ErrorMessage, DataUnavailable } from '@/components'
import { getOptions } from '@/components/DataTable/DateRangeFilter'
import { nationalPolicyTableQuery } from '@/graphql/national'

import { useSearchParams } from '@/hooks'

const useStyles = makeStyles((theme) => ({
  link: theme.tooltiplink,
  gridItem: {
    padding: '20px 0'
  }
}))

/**
 * National Table Card
 * Showcasing relevant policy items in a spreadsheet like format with expandable rows.
 * @component
 */
export default function NationalTableCard() {
  const classes = useStyles()
  const { tags, endDate, startDate } = useSearchParams()
  const [disableFilterButton, setDisableFilterButton] = useState(false)
  const [queryData, setQueryData] = useState([])

  const handleFormErrors = (errors) => setDisableFilterButton(errors)

  const { loading, error } = useQuery(nationalPolicyTableQuery, {
    variables: {
      tags,
      startDate,
      endDate
    },
    onCompleted: (data) => {
      setQueryData(data.nationalPolicyTableData)
    }
  })

  const infoData = [
    {
      title: 'What are the relevant legislative items?',
      description: [
        "This table lists the legislative items that match your search choices. Each row represents a unique legislative item, and the columns show the legislative item's designation, congressional session, current status, title and description, and the date when the status changed most recently. If available, clicking on an item designation will open a detailed view of the legislative item on Congress.gov. You can use tools above the table to change the items shown in the table."
      ],
      dimension: [
        '[Search Button] Type words to search for in the title and description',
        '[Download CSV Button] Download the information in the table in comma-separated values (CSV) format',
        '[Filter Table Button] Apply a filter or conduct a search'
      ],
      note: 'The data in this table is from Congress.gov via LegiScan.'
    }
  ]

  /**
   * Function that checks if row can be expanded.
   * @constructor
   * @param {Object} expandedRows - Object containing an array of data
   * @param {Number} dataIndex - Index of Data Element
   */
  const checkIfRowExpandable = (dataIndex, expandedRows) => {
    // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
    if (expandedRows.data.length > 4 && expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0)
      return false
    return true
  }

  /**
   * Function that expands rows once the user clicks the arrow
   * on the left hand side of the spreadsheet row.
   * @constructor
   * @param {[]} rowData - Array of infomation about selected row.
   */
  const renderExpandableRow = (rowData) => {
    const colSpan = rowData.length + 1
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Topics data={rowData[5]} />
        </TableCell>
      </TableRow>
    )
  }

  const columns = [
    {
      name: 'billNumber',
      label: 'Item',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const values = queryData[dataIndex]
          return values.url ? (
            <Link className={classes.link} href={values.url} target='_blank' rel='noopener'>
              {values.billNumber}
            </Link>
          ) : (
            values.billNumber
          )
        }
      }
    },
    {
      name: 'session',
      label: 'Session',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'description',
      label: 'Title and Description',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true
      }
    },
    {
      name: 'lastActionDate',
      label: 'Last Action',
      options: {
        customBodyRenderLite: (dataIndex) =>
          queryData[dataIndex].lastActionDate === 'Invalid date' ? 'Unknown' : queryData[dataIndex].lastActionDate,
        filter: true,
        sort: true,
        filterType: 'custom',
        ...getOptions(startDate, endDate, handleFormErrors)
      }
    },
    {
      name: 'topics',
      label: 'Topics',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        display: 'excluded',
        searchable: true
      }
    },
    {
      name: 'url',
      label: 'Url',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'billType',
      label: 'Policy Types',
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: 'role',
      label: 'Chamber',
      options: {
        filter: true,
        sort: false,
        display: false
      }
    }
  ]

  return (
    <Card infoData={infoData} downloadDisabled>
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {queryData.length === 0 && !loading && <DataUnavailable />}
      {!loading && queryData.length > 0 && !error && (
        <Grid item className={classes.gridItem}>
          <DataTableLayout
            columns={columns}
            data={queryData}
            otherOptions={{
              disableFilterButton,
              isRowExpandable: checkIfRowExpandable,
              renderExpandableRow,
              expandableRows: true
            }}
          />
        </Grid>
      )}
    </Card>
  )
}
