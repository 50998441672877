import { Layer, Source } from 'react-map-gl'
import { useBasePath } from '@/hooks'

export default function StateOutlineLayer() {
  const base = useBasePath()

  return (
    <Source type='geojson' data={`${base}/geojson/2018_us_state.geojson`}>
      <Layer
        type='line'
        id='state-borders'
        source='geojson'
        paint={{
          'line-color': 'rgba(0,0,0,0.8)',
          'line-width': 0.2
        }}
      />
    </Source>
  )
}
