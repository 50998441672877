import React from 'react'
import { Icon } from 'semantic-ui-react'

export default function NotFound() {
  return (
    <div style={{ margin: '1rem' }}>
      <h1>
        <Icon aria-label='warning circle' name='warning circle' />
        404
      </h1>
      <h3>Page not found. If you believe this is an error, please contact IT help for support.</h3>
    </div>
  )
}
