import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

/**
 * Message component that shows if a specific card is failing to load.
 * @component
 */
export default function ErrorMesage() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant='h4'>
        Something went wrong. Please try again in five minutes. If the issue persists, contact IT help for support.
      </Typography>
    </div>
  )
}
