import { gql } from '@apollo/client'

const topLevelNationalKpisQuery = gql`
  query topLevelNationalKpisQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!, $policyType: String!) {
    topLevelNationalPolicyKpisData(tags: $tags, startDate: $startDate, endDate: $endDate, policyType: $policyType) {
      policyCount
      billCount {
        dimension
        count
      }
      resolutionCount {
        dimension
        count
      }
      billTotalCount
      resolutionTotalCount
    }
  }
`
export { topLevelNationalKpisQuery }
