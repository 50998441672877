import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ReactDOM from 'react-dom'

import 'fontsource-roboto'
import './index.css'

import Router from './router'
import theme from './style/theme'
import client from './graphql/client'
import 'mapbox-gl/dist/mapbox-gl.css'

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router />
      </ApolloProvider>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)
