import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const StyledButton = withStyles({
  root: {
    height: 42,
    width: 140,
    backgroundColor: '#ebbc2f',
    color: '#292935',
    textTransform: 'none',
    fontSize: '1.1rem'
  },
  disabled: {
    color: '#2929359e !important',
    backgroundColor: '#808080a1 !important'
  }
})(Button)

const useStyles = makeStyles(() => ({
  applyButtonWrapper: {
    width: 140,
    position: 'relative',
    marginTop: 40
  },
  buttonProgress: {
    color: '#292935',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

export default function FilterFooter({ applyFilters, filtering, disabled, buttonText }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  // after the loading spinner has been rendered
  // call the applyFilters button to do the filtering
  useEffect(() => {
    if (loading) {
      applyFilters()
    }
  }, [loading, applyFilters])

  // stop the loading spinner when the filtering
  // is done
  useEffect(() => {
    if (!filtering) {
      setLoading(false)
    }
  }, [filtering])

  // start the loading spinner
  const handleSubmit = () => {
    setLoading(true)
  }

  // i could only get the disabled state to show for the button
  // if i separated them out like below. if i tried applying disabled={loading}
  // to the first button, it wouldn't change states.
  return (
    <div className={classes.applyButtonWrapper}>
      {!loading && !disabled && (
        <StyledButton variant='contained' onClick={handleSubmit}>
          {buttonText}
        </StyledButton>
      )}
      {(loading || disabled) && (
        <StyledButton variant='contained' disabled disableElevation>
          {buttonText}
        </StyledButton>
      )}
      {loading && <CircularProgress disableShrink size={24} className={classes.buttonProgress} />}
    </div>
  )
}

FilterFooter.propTypes = {
  /**
   * The function to call when the user clicks the apply button
   */
  applyFilters: PropTypes.func.isRequired,
  /**
   * Text of the apply button
   */
  buttonText: PropTypes.any,
  /**
   * If the filtering is happening. Button will be disabled and
   * a spinner will show
   */
  filtering: PropTypes.bool,
  /**
   * If the button should be disabled. Useful for if there are
   * errors
   */
  disabled: PropTypes.bool
}

FilterFooter.defaultProps = {
  filtering: false,
  disabled: false,
  buttonText: 'Apply Filters'
}
