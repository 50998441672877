import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Pluralize from 'react-pluralize'
import { useSearchParams } from '@/hooks'
import { searchedTags, getFormattedNumber } from '@/utils/helper'
import { DataUnavailable } from '@/components'

const useStyles = makeStyles((theme) => ({
  mapKpiTitle: theme.kpiTitle,
  dataStory: theme.kpidescription,
  Kpi: theme.kpiStatistic
}))

export default function DotPlotKpi({ totalActions, totalPolicyItems, session, avgAction }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const tagNames = Object.keys(searchParams.tags)
  const searchedTerms = searchedTags(tagNames)

  return totalActions === 0 ? (
    <DataUnavailable />
  ) : (
    <>
      <Typography className={classes.Kpi}>{getFormattedNumber(totalActions, 0, 1)}</Typography>
      <Typography className={classes.mapKpiTitle}>ACTIONS</Typography>

      <Typography className={classes.dataStory}>
        {getFormattedNumber(totalActions, 0, 1)} {totalActions > 1 ? 'actions' : 'action'} have been taken on{' '}
        <Pluralize singular='legislative item' count={totalPolicyItems} /> related to <i>{searchedTerms}</i> from the{' '}
        {session}. On average, each item has <Pluralize singular='action' count={avgAction} />.
      </Typography>
    </>
  )
}
