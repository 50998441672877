import React, { useEffect, useRef } from 'react'
import Grow from '@material-ui/core/Grow'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { makeStyles } from '@material-ui/core/styles'
import { debounce } from '@/utils/helper'

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flex: '1 0 auto'
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      marginTop: '10px',
      marginRight: '8px'
    },
    searchText: {
      flex: '0.8 0'
    },
    clearIcon: {
      '&:hover': {
        color: theme.palette.error.main
      }
    }
  }),
  { name: 'MUIDataTableSearch' }
)

export const DebounceSearch = ({ options, onHide, searchText, debounceWait, onSearch }) => {
  const searchFieldRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    const handleOnKeyDown = (event) => {
      if (event.keyCode === 27) {
        onHide()
      }
    }

    document.addEventListener('keydown', handleOnKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown, false)
    }
  }, [onHide])

  // debounces the search
  const debouncedSearch = debounce((value) => {
    onSearch(value)
  }, debounceWait)

  const handleTextChangeWrapper = (debouncedSearch) => (event) => debouncedSearch(event.target.value)

  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.main}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          className={classes.searchText}
          InputProps={{
            'data-test-id': options.textLabels.toolbar.search,
            'aria-label': options.textLabels.toolbar.search
          }}
          defaultValue={searchText}
          onChange={handleTextChangeWrapper(debouncedSearch)}
          fullWidth
          inputRef={(el) => (searchFieldRef.current = el)}
          placeholder={options.searchPlaceholder}
          {...(options.searchProps ? options.searchProps : {})}
        />
        <IconButton className={classes.clearIcon} onClick={onHide}>
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  )
}

export function debounceSearchRender(debounceWait = 200) {
  return (searchText, handleSearch, hideSearch, options) => (
    <DebounceSearch
      searchText={searchText}
      onSearch={handleSearch}
      onHide={hideSearch}
      options={options}
      debounceWait={debounceWait}
    />
  )
}
