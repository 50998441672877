import { IconButton, Tooltip } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import PropTypes from 'prop-types'
import papa from 'papaparse'

/**
 * Shows a button for CSV download.
 * @component
 */
export default function DownloadButton({ data, fileName, title }) {
  /**
   * Callback function when user clicks the download icon on a card.
   *
   */
  const handleClick = () => {
    const _data = data.map(({ __typename, ...keep }) => keep)
    const csvData = new Blob([papa.unparse(_data)], { type: 'text/csv' })
    const csvURL = URL.createObjectURL(csvData)
    const link = document.createElement('a')
    link.setAttribute('href', csvURL)
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }

  const label = `Download data for graphic: ${title}`

  return (
    <Tooltip title='Download Data'>
      <IconButton aria-label={label} onClick={handleClick} style={{ color: 'white', paddingRight: '0px' }}>
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  )
}

DownloadButton.propTypes = {
  /**
   * Data to be downloaded into a CSV.
   */
  data: PropTypes.array,
  /**
   * Name given to file if CSV download button is clicked.
   */
  fileName: PropTypes.string.isRequired
  /**
   * title of the card for which this download button is used
   */
}

DownloadButton.defaultProps = {
  data: [],
  fileName: 'example'
}
