import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { StatusBreakdown, ClickableKPI } from './components'

/**
 * This shows the breakdown of the different policy types for the
 * national tab.
 * @component
 */
export default function PolicyTypeKpi({ bills, resolutions, totalBills, totalResolutions }) {
  const [policyType, updatePolicyType] = useState('Bill')

  return (
    <Grid item style={{ padding: '20px 60px 20px 0', flex: '1 1 65%', flexWrap: 'nowrap' }} container>
      <Grid item style={{ flex: '1 1 50%', flexDirection: 'column' }} container role='tablist'>
        <Grid item style={{ flex: 1 }}>
          <ClickableKPI
            count={totalBills}
            name='Bill'
            onClick={() => updatePolicyType('Bill')}
            selected={policyType === 'Bill'}
          />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <ClickableKPI
            count={totalResolutions}
            name='Resolution'
            onClick={() => updatePolicyType('Resolution')}
            selected={policyType === 'Resolution'}
          />
        </Grid>
      </Grid>
      <Grid item style={{ flex: '1 1 50%', backgroundColor: '#49495F' }}>
        <StatusBreakdown items={policyType === 'Bill' ? bills : resolutions} />
      </Grid>
    </Grid>
  )
}

PolicyTypeKpi.propTypes = {
  /**
   * list of bill statuses and counts
   */
  bills: PropTypes.array,
  /**
   * list of resolution statuses and counts
   */
  resolutions: PropTypes.array,
  /**
   * total number of bills
   */
  totalBills: PropTypes.number,
  /**
   * total number of resolutions
   */
  totalResolutions: PropTypes.number
}

PolicyTypeKpi.defaultProps = {
  bills: [],
  resolutions: [],
  totalBills: 0,
  totalResolutions: 0
}
