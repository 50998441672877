import { useState, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'

import { TopicsKpi, TopicsSankey } from './components'

import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { filter, slice, chain, mergeWith, isEmpty } from 'lodash'

import { Card, AutoCompleteSearch, LoadingSpinner, ErrorMessage, DataUnavailable, KPI } from '@/components'
import { topicByStatusQuery } from '@/graphql/national/'
import { useSearchParams } from '@/hooks'

import { PolicyEnum, MasterTabEnum, BillStatusEnum } from '@/utils/constants'
import { formatTopicsSankeyChart } from '@/utils/formatNivo'

const useStyles = makeStyles({
  chartTitle: {
    color: '#fff',
    letterSpacing: '0.2em',
    marginRight: '.8rem'
  },
  chartMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  filterGroup: {
    flexGrow: 1,
    width: '60%',
    margin: '0 1rem 0 0'
  }
})

/**
 * Topic By Status Card
 * Show casing number of subjects represented
 * along with topics Sankey Graph.
 * @component
 */
export default function TopicsByStatus() {
  const [policyCount, setPolicyCount] = useState(null)

  const [aggData, setAggData] = useState(null)
  const [formattedData, setFormattedData] = useState(null)

  const [queryData, setQueryData] = useState(null)
  const [downloadData, setDownloadData] = useState([])

  const [searchFilter, setSearchFilter] = useState('')

  const [hasLoaded, setHasLoaded] = useState(false)
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  const [topFiveData, setTopFiveData] = useState(null)
  const [showA, setShowA] = useState('pre-render-national-topics')
  const chartRef = useRef(null)

  const classes = useStyles()

  // Variables for useQuery

  const { tags, startDate, endDate } = useSearchParams()
  const policyType = PolicyEnum.National
  const selectedMasterTab = MasterTabEnum.NationalPolicy

  /**
   * Function that maps data to be donwloaded as csv
   * @function
   * @param {Object[]} data - Array of objects to be downloaded in a CSV format
   */
  const downloadCSV = (data) =>
    data.map((x) => ({
      status: BillStatusEnum[x.status],
      subjectName: x.subjectName,
      subjectId: x.subjectId,
      statusCount: x.statusCount
    }))

  /**
   * Function that combines array of objects together
   * based on a specific property of that object,
   * @function
   * @param {Object[]} data - Array of objects to be grouped together
   * @param {string} groupBy - Property of obj. to group by
   */
  function aggregateSankey(data, groupBy) {
    return chain(data)
      .groupBy(groupBy)
      .map((obj) =>
        mergeWith.apply(
          obj,
          [{}].concat(obj, (obj, src, key) => (key === 'statusCount' ? (obj || 0) + src : undefined))
        )
      )
      .orderBy(['statusCount'], ['desc'])
      .value()
  }

  /**
   * Function that gets top 5 subject names from
   * aggregatedData array. Then finds the same subjectName
   * from the totalData obj recived from query.
   * @function
   * @param {Object[]} tData - nationalPolicyTopicByStatus.
   * @param {Object} aData - Agreggated Data that is passe in.
   */
  function getTopFiveTopicsData(tData, aData) {
    const topFiveTopics = slice(aData, 0, 5).map((obj) => obj.subjectName)

    const filteredData = []
    topFiveTopics.forEach((topic) => {
      let filtered = filter(tData, (o) => o.subjectName === topic)

      const formattedByStatus = aggregateSankey(filtered, 'status')

      filteredData.push(formattedByStatus)
    })

    return filteredData
  }

  const { loading, error } = useQuery(topicByStatusQuery, {
    variables: {
      tags,
      startDate,
      endDate,
      policyType,
      selectedMasterTab
    },
    onCompleted: ({ nationalPolicyTopicByStatus: npt, topLevelNationalPolicyKpisData: policyKPI }) => {
      let aData = aggregateSankey(npt, 'subjectName')
      const topFiveTopicsData = getTopFiveTopicsData(npt, aData)
      const fData = formatTopicsSankeyChart(topFiveTopicsData)

      setIsDataEmpty(isEmpty(npt))
      setTopFiveData(topFiveTopicsData)

      setQueryData(npt)
      setFormattedData(fData)
      setPolicyCount(policyKPI.policyCount)
      setAggData(aData)

      const csvData = downloadCSV(npt)
      setDownloadData([...csvData])

      setHasLoaded(policyKPI.policyCount > 0 && fData && aData)
    }
  })

  useEffect(() => {
    if (queryData) {
      let fData, searched, csvData, filtered
      if (searchFilter) {
        filtered = filter(queryData, (o) => o.subjectName === searchFilter)
        searched = aggregateSankey(filtered, 'status')
      }

      csvData = searchFilter ? downloadCSV(filtered) : downloadCSV(queryData)
      fData = searchFilter ? formatTopicsSankeyChart(searched) : formatTopicsSankeyChart(topFiveData)

      setDownloadData([...csvData])
      setFormattedData(fData)
    }
  }, [searchFilter])

  const infoData = [
    {
      title: 'What subjects are represented?',
      description: [
        'This section matches the legislative status (such as introduced or engrossed) and legislative subjects (such as health promotion and preventive care) per the Congressional Research Service.'
      ],
      dimension: [
        '[Left Side Blocks] Legislative status',
        '[Right Side Blocks] Legislative subjects',
        '[Flow Thickness] Proportional to the number of legislative items that have a given status and subject'
      ],
      note: null
    }
  ]
  /**
   * Handle change function that sets searchFilter.
   * @constructor
   * @param {string} selected - Selected Value.
   */
  const handleChange = (e, selected) => setSearchFilter(selected)

  const resetFilter = () => setSearchFilter('')

  useEffect(() => {
    if (hasLoaded && chartRef.current) {
      const el2 = chartRef.current
      let domElements
      domElements = el2.querySelectorAll("svg[role='img']")
      domElements.forEach((item) => {
        item.setAttribute('title', 'Subjects Represented')
      })
      setShowA('national-topics-post-render')
    }
  }, [hasLoaded, chartRef.current])

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={error || loading || isDataEmpty}
      fileName='TopicsByStatus'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {hasLoaded && !isDataEmpty && (
        <>
          <KPI>
            <TopicsKpi data={queryData} policyTotal={policyCount} topicCount={aggData.length} />
          </KPI>
          <Grid container item style={{ flex: '1 1 65%', padding: 20 }} ref={chartRef} id={showA}>
            <div className={classes.chartMenuContainer}>
              <Typography variant='h5' className={classes.chartTitle}>
                SUBJECTS BY STATUS
              </Typography>
              <div className={classes.filterGroup}>
                <AutoCompleteSearch
                  ariaLabel='Search Subject Text Bar'
                  onChange={handleChange}
                  value={searchFilter}
                  options={aggData.map((option) => option.subjectName)}
                  placeholder='Choose subject'
                  reset={resetFilter}
                />
              </div>
            </div>
            <TopicsSankey sankeyData={formattedData} />
          </Grid>
        </>
      )}
    </Card>
  )
}
