import { arrayToObj } from '@/utils/helper'

const defaultParams = {
  tags: [],
  startDate: '',
  endDate: ''
}

export default () => {
  const searchParams = JSON.parse(localStorage.getItem('searchParams'))
  if (searchParams) {
    const { tags, startDate, endDate } = searchParams
    return {
      tags: arrayToObj(tags),
      startDate,
      endDate
    }
  }
  return defaultParams
}
