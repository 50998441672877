import { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/styles'
import { InputBase, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'

const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    fontWeight: theme.robotoLight,
    padding: '4px 26px 4px 12px',
    '&::placeholder': {
      color: 'white'
    }
  }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '92%'
  },
  search: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(41,41,53,.75)',
    border: '1px solid #18181e',
    marginLeft: theme.spacing(2),
    display: 'flex',
    width: '92%',
    height: '12%'
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: 3
    },
    fontWeight: theme
  },
  option: {
    backgroundColor: theme.palette.background.default
  },
  popper: {
    backgroundColor: theme.palette.background.default
  },
  clearIndicator: {
    color: '#fff'
  },
  iconButton: {
    color: 'white',
    padding: '0 5px 0 0'
  },
  closeIcon: {
    fontSize: '1rem'
  }
}))

/**
 * Component for the autocomplete search bar.
 * @component
 */
export default function AutoCompleteSearch({ onChange, options, placeholder, reset, ariaLabel }) {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e, value) => setInputValue(value)
  const resetInput = () => {
    setInputValue('')
    reset()
  }

  return (
    <>
      <Autocomplete
        id='topics-filter'
        freeSolo
        size='small'
        autoHighlight
        classes={{
          root: classes.root,
          inputRoot: classes.inputRoot,
          option: classes.option,
          popper: classes.popper,
          clearIndicator: classes.clearIndicator
        }}
        options={options}
        onChange={onChange}
        onInputChange={handleChange}
        inputValue={inputValue}
        renderInput={(params) => (
          <div className={classes.search} ref={params.InputProps.ref}>
            <CustomInput
              placeholder={placeholder}
              variant='outlined'
              inputProps={{ name: ariaLabel, 'aria-label': ariaLabel, ...params.inputProps }}
              endAdornment={
                params.inputProps.value && (
                  <IconButton aria-label='clear' classes={{ root: classes.iconButton }} onClick={resetInput}>
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                )
              }
            />
          </div>
        )}
      />
    </>
  )
}

AutoCompleteSearch.propTypes = {
  /**
   * Label for Aria in Drop Down
   */
  ariaLabel: PropTypes.string,
  /**
   * Placeholder string for text input
   */
  placeholder: PropTypes.string,
  /**
   * Callback function to reset input
   */
  reset: PropTypes.oneOfType([PropTypes.func]),
  /**
   * Callback function when menu item is selected.
   */
  onChange: PropTypes.func,
  /**
   * Options array to populate drop down.
   */
  options: PropTypes.array
}

AutoCompleteSearch.defaultProps = {
  ariaLabel: '',
  onChange: () => {},
  reset: () => {},
  options: [],
  placeholder: ''
}
