/* eslint-disable no-restricted-syntax */
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Pluralize from 'react-pluralize'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  tooltipContainer: {
    backgroundColor: 'rgba(34,34,44,.95)',
    borderRadius: 7,
    color: '#fff',
    width: '25vw'
  },
  tooltipContent: {
    textAlign: 'left',
    paddingLeft: '0.5rem',
    paddingRight: '0.4rem',
    paddingBottom: '0.2rem'
  },
  gridContainer: {
    paddingTop: '0.6rem',
    paddingBottom: '0.6rem'
  }
})

export default function DotTooltip({ plot }) {
  const classes = useStyles()
  const getTotalActions = (points) => {
    let totalActionCount = 0
    let billNumber
    let lastActionDate
    let lastAction
    let title

    if (points && points.length !== 0) {
      billNumber = points[0].data.y
      lastActionDate = points[0].data.lastActionDate
      lastAction = points[0].data.lastAction
      title = points[0].data.title
    }
    for (const p of points) {
      totalActionCount += p.data.actionCount
    }
    const point = { billNumber, totalActionCount, lastActionDate, lastAction, title }
    return point
  }

  const pointData = getTotalActions(plot.slice.points)

  return (
    <div className={classes.tooltipContainer}>
      <Grid className={classes.gridContainer}>
        <div className={classes.tooltipContent}>
          {pointData.billNumber}{' '}
          {pointData.title.length > 25 ? `${pointData.title.substring(0, 25)}...` : pointData.title}
        </div>
        <div className={classes.tooltipContent}>
          <Pluralize singular='Total Action' count={pointData.totalActionCount} />
        </div>
        <div className={classes.tooltipContent}>
          {' '}
          Last Action: {pointData.lastActionDate} - {pointData.lastAction}
        </div>
      </Grid>
    </div>
  )
}
