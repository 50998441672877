import { Grid, Link, makeStyles, createStyles } from '@material-ui/core'

import { FooterSection } from '.'

const useStyles = makeStyles((theme) =>
  createStyles({
    cdcIcon: {
      '&:before': {
        color: theme.palette.footer.icons,
        display: 'inline-block',
        fontFamily: 'cdcIconFont',
        fontSize: '1.7em',
        fontStyle: 'normal',
        lineHeight: '1',
        lineWeight: '400',
        textDecoration: 'inherit',
        textRendering: 'auto',
        textTransform: 'none',
        verticalAlign: 'middle'
      }
    },
    email: {
      '&:before': {
        content: '"\\f23f"'
      }
    },
    facebook: {
      '&:before': {
        content: '"\\f1f9"'
      }
    },
    instagram: {
      '&:before': {
        content: '"\\f1dd"'
      }
    },

    linkedin: {
      '&:before': {
        content: '"\\f1fd"'
      }
    },
    rss: {
      '&:before': {
        content: '"\\f200"'
      }
    },
    snapchat: {
      '&:before': {
        content: '"\\f1e2"'
      }
    },
    srOnly: {
      border: '0',
      clip: 'rect(0, 0, 0, 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: '0',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px'
    },
    syndicate: {
      '&:before': {
        content: '"\\f201"'
      }
    },
    tv: {
      '&:before': {
        content: '"\\f1d9"'
      }
    },
    twitter: {
      '&:before': {
        content: '"\\f203"'
      }
    },
    youtube: {
      '&:before': {
        content: '"\\f204"'
      }
    }
  })
)

/**
 * Displays the Connect section of the CDC Footer
 * @component
 */
export default function Connect() {
  const classes = useStyles()

  return (
    <FooterSection title='CONNECT WITH CDC'>
      <Grid container spacing={2} style={{ maxWidth: '200px' }} wrap='wrap'>
        <Grid item>
          <Link href='https://www.facebook.com/CDC' rel='noopener noreferrer' target='_blank'>
            <span className={classes.srOnly}>Facebook</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.facebook}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://twitter.com/CDCgov' rel='noopener noreferrer' target='_blank'>
            <span className={classes.srOnly}>Twitter</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.twitter}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://www.instagram.com/CDCgov/' rel='noopener noreferrer' target='_blank'>
            <span className={classes.srOnly}>Instagram</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.instagram}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link
            href='https://www.linkedin.com/company/centers-for-disease-control-and-prevention'
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classes.srOnly}>LinkedIn</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.linkedin}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://www.snapchat.com/add/cdcgov' rel='noopener noreferrer' target='_blank'>
            <span className={classes.srOnly}>Snapchat</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.snapchat}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://www.youtube.com/user/CDCstreamingHealth' rel='noopener noreferrer' target='_blank'>
            <span className={classes.srOnly}>Youtube</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.youtube}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link
            className='footer-syndlink'
            href='https://tools.cdc.gov/medialibrary/index.aspx#/sharecontent/https://www.cdc.gov/index.htm'
          >
            <span className={classes.srOnly}>Syndicate</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.syndicate}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://www.cdc.gov/cdctv'>
            <span className={classes.srOnly}>CDC TV</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.tv}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://tools.cdc.gov/podcasts/rss.asp'>
            <span className={classes.srOnly}>RSS</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.rss}`} />
          </Link>
        </Grid>
        <Grid item>
          <Link href='https://wwwn.cdc.gov/dcs/RequestForm.aspx'>
            <span className={classes.srOnly}>Email</span>
            <span aria-hidden='true' className={`${classes.cdcIcon} ${classes.email}`} />
          </Link>
        </Grid>
      </Grid>
    </FooterSection>
  )
}
