import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    height: '8rem',
    marginTop: '8rem',
    textAlign: 'center'
  },
  h5variant: {
    color: 'white'
  }
}))

/**
 * Placeholder component for when developing new
 * tabs / views in the application. Not currently being used.
 * @component
 */
export default function UnderDevelopment() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography classes={{ h5: classes.h5variant }} variant='h5'>
        Sorry. This tab is currently in development.
      </Typography>
    </div>
  )
}
