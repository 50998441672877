import { gql } from '@apollo/client'

const nationalBillProgressQuery = gql`
  query nationalBillProgressQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!) {
    nationalBillProgress(tags: $tags, startDate: $startDate, endDate: $endDate) {
      session
      historyData {
        billId
        billNumber
        date
        actionCount
        status
        lastActionDate
        lastAction
        title
      }
    }
  }
`

export { nationalBillProgressQuery }
