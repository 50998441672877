import { ResponsiveLine } from '@nivo/line'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'

import { Dot, DotTooltip } from './components'

const useStyles = makeStyles({
  root: {
    padding: 0,
    width: '100%',
    height: 500,
    overflow: 'auto'
  }
})

/**
 * Shows a Dot Plot
 * @component
 */
export default function DotPlot({ data, totalItems }) {
  const classes = useStyles()
  const colors = ['#90be6d']
  const chartHeight = totalItems * 33

  const [rightMargin, setRightMargin] = useState(0)
  const chartWrapper = useRef(null)
  const chartTitle = useRef(null)

  useEffect(() => {
    if (chartWrapper.current && chartWrapper.current.scrollHeight > chartWrapper.current.clientHeight) {
      // scroll bar is visible
      setRightMargin(chartWrapper.current.offsetWidth - chartWrapper.current.children[0].offsetWidth)
    }
    if (chartWrapper.current) {
      const el2 = chartWrapper.current
      let domElements
      domElements = el2.querySelectorAll("svg[role='img']")
      domElements.forEach((item) => {
        item.setAttribute('title', 'Legislative Items Over Time Axis')
      })
    }
  }, [chartWrapper.current])

  useEffect(() => {
    if (chartTitle.current) {
      const el2 = chartTitle.current
      let domElements
      domElements = el2.querySelectorAll("svg[role='img']")
      domElements.forEach((item) => {
        item.setAttribute('title', 'Legislative Items Over Time')
      })
    }
  }, [chartTitle.current])

  /**
   * Returns just the first letter of the month name to use for the axis
   * @param {Date} v date
   * @returns {String}
   */
  const generateAxisFormat = (v) => {
    if (v) {
      const month = v.getUTCMonth()
      const year = v.getUTCFullYear()
      const point = new Date(year, month, 1)
      const xPoint = point.toLocaleString('default', { month: 'short' }).charAt(0)
      return xPoint
    } else {
      return ''
    }
  }

  /**
   * Returns the height to use for the wrapper element
   * @param {Number} chartHeight
   * @param {Number} totalItems
   * @returns {Number}
   */
  const getBaseHeight = (chartHeight, totalItems) => (totalItems >= 10 && chartHeight >= 500 ? 500 : 410)

  const commonTheme = {
    axis: {
      ticks: {
        text: {
          fill: '#ffffff',
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: '0.9rem'
        }
      }
    },
    textColor: 'fff',
    labels: {
      text: {
        color: '#ffffff',
        fontWeight: 'bold',
        letterSpacing: '0.2rem'
      }
    }
  }

  const commonProps = {
    axisBottom: null,
    axisRight: null,
    animate: false,
    colors,
    enableGridX: false,
    useMesh: false,
    xFormat: 'time:%Y-%m-%d',
    xScale: {
      type: 'time',
      format: '%Y-%m-%d',
      precision: 'month'
    },
    yScale: { type: 'point' }
  }

  return (
    <>
      <div style={{ height: 40, width: '100%' }} ref={chartTitle}>
        <ResponsiveLine
          {...commonProps}
          axisLeft={null}
          axisTop={{
            format: (v) => generateAxisFormat(v),
            tickValues: 'every month',
            tickPadding: 5,
            tickSize: 8,
            orient: 'top',
            legend: ''
          }}
          data={[data[0]]}
          enableCrosshair={false}
          enablePoints={false}
          enableGridY={false}
          height={40}
          lineWidth={0}
          margin={{ top: 40, right: rightMargin + 24, bottom: 80, left: 80 }}
          pointSize={0}
          theme={commonTheme}
        />
      </div>
      <div
        className={classes.root}
        style={{ height: getBaseHeight(chartHeight, totalItems) }}
        ref={chartWrapper}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex='0'
      >
        <ResponsiveLine
          {...commonProps}
          axisLeft={{ tickPadding: 11 }}
          axisTop={null}
          data={data}
          enableGridY
          enableSlices='y'
          height={chartHeight <= 400 ? 400 : chartHeight}
          margin={{ top: 33, right: 24, bottom: 80, left: 80 }}
          pointSize={22}
          pointSymbol={(dot) => <Dot dot={dot} />}
          sliceTooltip={(plot) => <DotTooltip plot={plot} />}
          theme={{
            ...commonTheme,
            axis: {
              ticks: {
                ...commonTheme.axis.ticks,
                line: {
                  strokeWidth: 0,
                  stroke: 'transparent'
                }
              }
            },
            tooltip: {
              container: {
                background: 'rgba(34,34,44,.95)',
                borderRadius: 7,
                color: '#fff'
              }
            }
          }}
        />
      </div>
    </>
  )
}

DotPlot.propTypes = {
  /**
   * data to show on the dot plot
   */
  data: PropTypes.array,
  /**
   * Total number of items show on the dot plot
   */
  totalItems: PropTypes.number
}

DotPlot.defaultProps = {
  data: [],
  totalItems: 0
}
