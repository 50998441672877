import { gql } from '@apollo/client'

const supporterDataQuery = gql`
  query supporterDataQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!, $policyType: String!) {
    topLevelBillKpisData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      congressBillCount
      congressBillStatus {
        status
        count
      }
    }

    billsByCongressMemberData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      count
      fullName
      party
      stateName
      type
      wikipediaLink
      bioguideId
      committee
      govtrackUrl
      status
      opensecretsId
    }

    topLevelNationalPolicyKpisData(tags: $tags, startDate: $startDate, endDate: $endDate, policyType: $policyType) {
      policyCount
    }
  }
`
export { supporterDataQuery }
