import { gql } from '@apollo/client'

const billsFeedQuery = gql`
  query supportersBills($opensecretsId: String!, $tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!) {
    supporterBillsByOpensecretsId(
      opensecretsId: $opensecretsId
      tags: $tags
      startDate: $startDate
      endDate: $endDate
    ) {
      billId
      billNumber
      title
      status
      statusDate
    }
  }
`
export { billsFeedQuery }
