import { useQuery, gql } from '@apollo/client'
import { resultsPageTutorialVar } from '@/graphql/cache'

const GET_RESULTSPAGE_TUTORIAL_TOGGLE = gql`
  query dashboardTutValue {
    resultsPageTutorialToggle @client
  }
`

export default () => {
  const { data } = useQuery(GET_RESULTSPAGE_TUTORIAL_TOGGLE)

  // update
  const update = (val) => resultsPageTutorialVar(val)
  return [data.resultsPageTutorialToggle || false, update]
}
