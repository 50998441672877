import { gql } from '@apollo/client'

const tabInfoQuery = gql`
  query tabInfoQuery($selectedMasterTab: Int!) {
    tabInfoContentData(selectedMasterTab: $selectedMasterTab) {
      mastertab
      title
      description
      dimension
      note
    }
  }
`

export { tabInfoQuery }
