import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles({
  rootStandard: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& button': {
      paddingLeft: 0,
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& input': {
      paddingTop: 6
    }
  },
  rootOutlined: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& label': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      },
      '& .MuiIconButton-root': {
        padding: 4,
        color: 'white'
      },
      '& .MuiOutlinedInput-root': {
        width: '75%'
      }
    }
  },
  popoverClass: {
    '& .MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton': {
      color: 'white'
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton.Mui-disabled': {
      color: `#ffffff33`
    },
    '& .MuiTypography-root.MuiPickersCalendarHeader-dayLabel.MuiTypography-caption': {
      color: 'white'
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected': {
      backgroundColor: 'white',
      color: '#3B3B4B'
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day': {
      color: 'white'
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-dayDisabled': {
      color: `#ffffff33`
    }
  }
})

/**
 * Wrapper for creating a date picker from material-ui/pickers library.
 * @component
 */
export default function DatePicker({
  className,
  inputVariant,
  onDateChange,
  ariaLabel,
  selectedDate,
  id,
  ...otherProps
}) {
  const defaultClasses = useStyles()

  let pickerClassName

  // if they've passed in their own class, use that otherwise use default
  if (className) {
    pickerClassName = className
  } else {
    pickerClassName = inputVariant === 'standard' ? defaultClasses.rootStandard : defaultClasses.rootOutlined
  }

  return (
    <KeyboardDatePicker
      aria-label={ariaLabel}
      autoOk
      className={pickerClassName}
      disableToolbar
      disableFuture
      emptyLabel=''
      format='MM/dd/yyyy'
      initialFocusedDate={new Date()}
      InputAdornmentProps={{
        position: 'start',
        style: {
          paddingLeft: 0
        }
      }}
      inputVariant={inputVariant}
      invalidDateMessage='Invalid Date'
      KeyboardButtonProps={{
        'aria-label': `Select ${otherProps.label ? otherProps.label : 'Date'}`
      }}
      id={id}
      label='Date'
      maxDate={new Date('2100-01-01')}
      minDate={new Date('1900-01-01')}
      maxDateMessage='Date cannot be after maximum date'
      minDateMessage='Date cannot be before minimum date'
      size='small'
      onChange={(date) => onDateChange(date)}
      placeholder='Select date'
      PopoverProps={{
        classes: { root: defaultClasses.popoverClass }
      }}
      value={selectedDate}
      variant='inline'
      {...otherProps}
    />
  )
}

DatePicker.propTypes = {
  /**
   * Label for Aria in Drop Down
   */
  ariaLabel: PropTypes.string,
  /**
   *  Bypass internal variant prop
   */
  inputVariant: PropTypes.string,
  /**
   *  Function to be called when date gets changed
   */
  onDateChange: PropTypes.func,
  /**
   * Selected date user picks, defaults to current
   */
  selectedDate: PropTypes.any
}

DatePicker.defaultProps = {
  ariaLabel: '',
  inputVariant: 'outlined',
  onDateChange: () => 'Empty function',
  selectedDate: new Date()
}
