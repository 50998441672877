import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@material-ui/core'

import { AutoCompleteDropdown, StatisticKPI, SingleSelect } from '@/components'
import { useSearchParams } from '@/hooks'
import useStyles from '@/style/KPIStyle'
import { getFormattedNumber, searchedTags } from '@/utils/helper'
import { id } from 'date-fns/locale'

export default function StateStatisticKPI({ stateCount, states, statistic, selectedState, onSelect }) {
  const classes = useStyles()

  const searchParams = useSearchParams()
  const tagNames = Object.keys(searchParams.tags)
  const searchedTerms = searchedTags(tagNames)
  let count = 0

  const statesList = [
    {
      id: 0,
      value: 'All',
      label: 'All States'
    },
    ,
    ...states
      .map((option) => {
        count++
        return { id: count, value: option.stateName, label: option.stateName }
      })
      .sort()
  ]

  return (
    <div>
      <StatisticKPI title='State Legislative Item' icon={faMapMarkedAlt} statistic={statistic} />
      <Typography align='left'>
        There {statistic > 1 ? 'are' : 'is'} {getFormattedNumber(statistic, 0, 1)} <i>{searchedTerms}</i>-related{' '}
        {statistic > 1 ? 'legislative items' : 'legislative item'} across {stateCount}{' '}
        {stateCount > 1 ? 'states and Washington DC.' : 'state and Washington DC.'}
      </Typography>
      <Typography
        className={classes.stateLabel}
        variant='caption'
        align='center'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'stretch', paddingTop: '2rem', width: '100%' }}
      >
        <span style={{ marginRight: '.8rem' }}>Select a state or Washington DC:</span>
        <SingleSelect
          onChange={onSelect}
          value={selectedState}
          options={statesList}
          name='States'
          ariaLabel='Select a state or territory'
        />
      </Typography>
    </div>
  )
}
