import { gql } from '@apollo/client'

const statePolicyHeatMapQuery = gql`
  query statePolicyHeatMapQuery($tags: JSON!) {
    statePolicyHeatMap(tags: $tags) {
      count
      state
      billType
      year
      name
    }
  }
`
export { statePolicyHeatMapQuery }
