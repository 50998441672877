import { useState } from 'react'
import ReactMapGL, { NavigationControl } from 'react-map-gl'
import { makeStyles } from '@material-ui/styles'

import Layers from './Layers'
import MapLegend from './MapLegend'
import HoverTooltip from './HoverTooltip'
import { DataUnavailable } from '@/components'

const useStyles = makeStyles({
  navControl: {
    position: 'absolute',
    left: 4,
    top: 4
  },
  legend: {
    position: 'absolute',
    left: 9,
    top: 243
  }
})

/**
 * This renders the actual map
 * @component
 */
export default function MapBox({
  onHover,
  onClick,
  counts,
  colorScale,
  countType,
  range,
  tooltip,
  hoveredState,
  noItemsTooltipText
}) {
  const classes = useStyles()
  const [viewport, setViewport] = useState({
    width: '100%',
    height: 400,
    latitude: 39.8283,
    longitude: -98.5795,
    zoom: 2.75
  })

  const { x, y, hoveredFeature } = hoveredState

  const getCursor = () => (hoveredFeature ? 'pointer' : 'grabbing')

  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      mapStyle='mapbox://styles/mapbox/dark-v10'
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      onHover={onHover}
      onClick={onClick}
      getCursor={getCursor}
      width='100%'
      minZoom={1}
    >
      <div className={classes.navControl}>
        <NavigationControl showCompass={false} />
      </div>
      {counts && counts.length !== 0 ? (
        <>
          <div className={classes.legend}>
            <MapLegend countType={countType} range={range} />
          </div>
          <Layers counts={counts} colorScale={colorScale} range={range} />
          <HoverTooltip items={counts} hoveredFeature={hoveredFeature} x={x} y={y} noItemsText={noItemsTooltipText}>
            {tooltip}
          </HoverTooltip>
        </>
      ) : (
        <DataUnavailable />
      )}
    </ReactMapGL>
  )
}
