import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'

/**
 * Wrapper for the footer container.
 * @component
 */
export default function FooterContainer({ children }) {
  return (
    <Box mx='auto' my='0' width='75%'>
      {children}
    </Box>
  )
}

FooterContainer.propTypes = {
  /**
   * Children to show
   */
  children: PropTypes.any
}
