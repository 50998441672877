import { filter, uniqBy, flatten, orderBy, maxBy, minBy } from 'lodash'
import { getWeek, format, addWeeks } from 'date-fns'
import moment from 'moment'
import { BillStatusEnum, States, StateHeatMapYears } from './constants'
const COLORS = ['#88C5FF', '#C296FF', '#00a2ae', '#FFBE0B', '#FF8166']

/**
 * Formats GraphQL response data to be used in a policy
 * over time line chart for nivo
 * @param {Array} policyItemOverTime
 * @returns {Object}
 */
export const formatPolicyItemsChart = (policyItemOverTime, startDate, endDate) => {
  const axisBottom = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -90,
    legend: 'DATE',
    legendOffset: 70,
    legendPosition: 'middle'
  }
  const legends = [
    {
      anchor: 'top-right',
      direction: 'column',
      justify: false,
      translateX: 118,
      translateY: 0,
      itemWidth: 100,
      itemHeight: 25,
      itemsSpacing: 5,
      symbolSize: 16,
      symbolShape: 'circle',
      itemDirection: 'left-to-right',
      itemTextColor: '#fff',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
          }
        }
      ]
    }
  ]

  const items = policyItemOverTime.map((item) => ({
    ...item,
    week: getWeek(new Date(item.statusDate))
  }))

  const lineData = []
  const dataYears = uniqBy(items, 'year').map((a) => a.year)
  const minWeek = minBy(items, 'week')
  const maxWeek = maxBy(items, 'week')

  let weekDate = new Date(minWeek.statusDate)
  let week = minWeek.week
  let weeks = [{ week, weekDate: format(new Date(weekDate), 'MMM dd') }]
  let done = false

  while (!done) {
    weekDate = addWeeks(weekDate, 1)
    week = getWeek(weekDate)
    if (week > maxWeek.week) {
      done = true
    } else {
      weeks.push({ week, weekDate: format(new Date(weekDate), 'MMM dd') })
    }
  }

  dataYears.forEach((year) => {
    const filtered = filter(items, (o) => o.year === year)
    const data = []
    let cumulativeCount = 0

    weeks.forEach((week) => {
      filtered.forEach((y) => {
        if (y.week === week.week) {
          cumulativeCount += y.count
        }
      })

      data.push({
        x: week.weekDate,
        y: cumulativeCount
      })
    })
    lineData.push({ id: year, data })
  })

  return { legends, axisBottom, data: lineData, colors: COLORS }
}

export const formatTopicsSankeyChart = (topicsByStatus) => {
  const flatData = flatten(topicsByStatus)

  const statusNodes = uniqBy(flatData, 'status').map((obj, idx) => {
    const statusObj = {
      id: BillStatusEnum[obj.status],
      nodeColor: COLORS[idx]
    }
    return statusObj
  })
  const topicNodes = uniqBy(flatData, 'subjectName').map((obj) => {
    const topicsObj = {
      id: obj.subjectName,
      nodeColor: 'rgb(67, 170, 139)'
    }
    return topicsObj
  })

  const links = flatData.map((obj) => {
    const { subjectName, status, statusCount } = obj
    const linkObj = {
      source: BillStatusEnum[status],
      target: subjectName,
      value: statusCount,
      startColor: '#4C4C56',
      endColor: '#4C4C56'
    }
    return linkObj
  })

  const sankeyData = {
    nodes: [...topicNodes, ...statusNodes],
    links
  }

  return { data: sankeyData, colors: COLORS }
}

export const formatHeatMapChart = (heatmapData) => {
  const processedHeatMapData = []

  // check if all states exists and fill in 0 for count
  for (const stateName of States) {
    const statePresent = heatmapData.filter((o) => o.state === stateName)
    if (statePresent && statePresent.length !== 0) {
      const heatMapObject = {}
      heatMapObject.state = stateName
      for (const year of StateHeatMapYears) {
        const matchedData = heatmapData.filter((o) => o.year.toString() === year && o.state === stateName)
        if (matchedData && matchedData.length !== 0) {
          heatMapObject[`${year.toString()}`] = matchedData[0].count
        } else {
          heatMapObject[`${year.toString()}`] = 0
        }
      }
      // after all the years are done for a state, push the object
      processedHeatMapData.push(heatMapObject)
    } else {
      const heatMapObject = {}
      heatMapObject.state = stateName
      for (const year of StateHeatMapYears) {
        heatMapObject[`${year.toString()}`] = 0
      }
      processedHeatMapData.push(heatMapObject)
    }
  }

  return processedHeatMapData
}

export const formatDotPlot = (historyData) => {
  const lineData = []

  const currentYear = new Date().getFullYear()
  const uniqueBillNumbers = uniqBy(historyData, 'billNumber').map((o) => o.billNumber)
  const orderedDates = orderBy(historyData, 'date', 'desc')

  const uniqueDates = uniqBy(orderedDates, 'date').map((o) => o.date)
  const recentYear = uniqueDates[0].substring(0, 4)
  const mostRecentMonth = uniqueDates[0].substring(5, 7)

  uniqueBillNumbers.forEach((billNumber) => {
    const data = []
    const billData = orderBy(
      filter(historyData, (o) => o.billNumber === billNumber),
      'date',
      'asc'
    )

    const lastIndex = billData.length - 1
    const lastBillItem = billData[lastIndex]
    const lastDate = new Date(lastBillItem.date)

    billData.forEach((item) => {
      const { billNumber, date, actionCount, status, lastActionDate, lastAction, title } = item

      data.push({
        x: moment.utc(date).format('YYYY-MM-DD'),
        y: billNumber,
        actionCount,
        status,
        lastAction,
        lastActionDate,
        title,
        lastItem: !!(status === 4 && lastIndex === billData.indexOf(item))
      })

      // Not to display the line in the graph if the status is passed
      if (status !== 4 && recentYear === currentYear.toString()) {
        data.push({
          x: moment.utc(new Date()).format('YYYY-MM-DD'),
          y: billNumber,
          actionCount: 0,
          status,
          lastAction,
          lastActionDate,
          title,
          lastItem: false
        })
      } else if (status !== 4 && mostRecentMonth === '01' && recentYear !== currentYear.toString()) {
        const dataExists = data.filter((o) => o.y === billNumber && o.x === uniqueDates[0])
        if (dataExists.length === 0) {
          data.push({
            x: `${recentYear}-02-01`,
            y: billNumber,
            actionCount: 0,
            status,
            lastAction,
            lastActionDate,
            title,
            lastItem: false
          })
        }
      } else if (status !== 4 && mostRecentMonth !== '01' && lastDate.getFullYear() !== currentYear.toString()) {
        let year = parseInt(recentYear, 10) + 1
        // if recent year + 1 is the current year, the line should end on the current month

        if (year.toString() === currentYear.toString()) {
          data.push({
            x: moment.utc(new Date()).format('YYYY-MM-DD'),
            y: billNumber,
            actionCount: 0,
            status,
            lastAction,
            lastActionDate,
            title,
            lastItem: false
          })
        } else {
          year = parseInt(recentYear, 10) + 2
          // if year is an odd number, the end date will the 1st Jan of that year
          if (year % 2 === 0) {
            year = parseInt(recentYear, 10) + 1
          }
          data.push({
            x: `${year}-02-01`,
            y: billNumber,
            actionCount: 0,
            status,
            lastAction,
            lastActionDate,
            title,
            lastItem: false
          })
        }
      }
    })

    lineData.push({ id: billNumber, data })
  })

  return { data: lineData, uniqueDates: uniqueDates.map((x) => ({ id: x, data: [{ x, y: 0 }] })) }
}
