import { gql } from '@apollo/client'

const stateMapCardQuery = gql`
  query stateMapCardQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!, $policyType: String!) {
    topLevelStatePolicyKpisData(tags: $tags, startDate: $startDate, endDate: $endDate, policyType: $policyType) {
      policyCount
      stateCount
    }

    statebillMapData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      stateFips
      state
      name
      count
      billType
      status
    }

    statePolicyStatusBreakDown(tags: $tags, startDate: $startDate, endDate: $endDate) {
      dimension
      count
    }

    stateMapFeedData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      billId
      billNumber
      billType
      description
      status
      state
      statusDate
      url
    }
  }
`

export { stateMapCardQuery }
