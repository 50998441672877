import PropTypes from 'prop-types'
import { ResponsiveSankey } from '@nivo/sankey'

/**
 * Component the SanKey used in the National Tab.
 * @component
 */
export default function SankeyChart({
  data,
  width,
  height,
  margin,
  align,
  colors,
  layout,
  nodeThickness,
  nodeInnerPadding,
  nodeSpacing,
  nodeBorderColor,
  linkOpacity,
  linkHoverOthersOpacity,
  enableLinkGradient,
  labelPosition,
  labelOrientation,
  labelPadding,
  labelTextColor,
  animate,
  legends,
  sort
}) {
  return (
    <div style={{ width, height }}>
      <ResponsiveSankey
        align={align}
        animate={animate}
        colorBy={(node) => node.nodeColor}
        colors={colors}
        data={data}
        enableLinkGradient={enableLinkGradient}
        labelOrientation={labelOrientation}
        labelPadding={labelPadding}
        labelPosition={labelPosition}
        labelTextColor={labelTextColor}
        layout={layout}
        legends={legends}
        linkHoverOthersOpacity={linkHoverOthersOpacity}
        linkOpacity={linkOpacity}
        margin={margin}
        motionDamping={13}
        motionStiffness={140}
        nodeBorderColor={nodeBorderColor}
        nodeBorderWidth={0}
        nodeInnerPadding={nodeInnerPadding}
        nodeOpacity={1}
        nodeSpacing={nodeSpacing}
        nodeThickness={nodeThickness}
        sort={sort}
        theme={{
          tooltip: {
            container: {
              background: 'rgb(34,34,44)',
              fontSize: '.7em'
            }
          },
          fontSize: '0.9rem'
        }}
      />
    </div>
  )
}

SankeyChart.propTypes = {
  data: PropTypes.object,
  width: PropTypes.any,
  height: PropTypes.number,
  margin: PropTypes.object,
  align: PropTypes.string,
  colors: PropTypes.any,
  layout: PropTypes.string,
  nodeThickness: PropTypes.number,
  nodeInnerPadding: PropTypes.number,
  nodeSpacing: PropTypes.number,
  nodeBorderColor: PropTypes.any,
  linkOpacity: PropTypes.number,
  linkHoverOthersOpacity: PropTypes.number,
  enableLinkGradient: PropTypes.bool,
  labelPosition: PropTypes.string,
  labelOrientation: PropTypes.string,
  labelPadding: PropTypes.number,
  labelTextColor: PropTypes.any,
  animate: PropTypes.bool,
  legends: PropTypes.array,
  sort: PropTypes.string
}

SankeyChart.defaultProps = {
  data: {},
  width: '100%',
  height: 375,
  margin: { top: 40, right: 50, bottom: 40, left: 50 },
  align: 'justify',
  colors: { scheme: 'nivo' },
  layout: 'horizontal',
  nodeThickness: 18,
  nodeInnerPadding: 3,
  nodeSpacing: 24,
  nodeBorderColor: { from: 'color', modifiers: [['darker', 0.8]] },
  linkOpacity: 0.5,
  linkHoverOthersOpacity: 0.1,
  enableLinkGradient: true,
  labelPosition: 'inside',
  labelOrientation: 'horizontal',
  labelPadding: 16,
  labelTextColor: 'white',
  animate: true,
  legends: [],
  sort: 'descending'
}
