import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ListItem, ListItemText } from '@material-ui/core'

import { SupporterFeedBillSubInfo, SupporterFeedBill } from './SupporterFeedBill'
import { FeedType } from '@/utils/constants'

const useStyles = makeStyles(() => ({
  primaryFeedItem: {
    color: 'white',
    fontSize: '0.9em'
  },
  secondaryFeedItem: {
    color: 'white',
    fontSize: '0.95em',
    paddingTop: '5px'
  }
}))

export default function SupporterFeedItem({ item, style, type }) {
  const classes = useStyles()

  // determine which feed components to use based on the type of feed: bill
  const PrimaryListComponent = SupporterFeedBill
  const SubListComponent = SupporterFeedBillSubInfo

  // if it's a national bill, we include the name of the member of congress sponsoring it
  const includeMember = type === FeedType.NATIONAL_BILL

  return (
    <ListItem style={style}>
      <ListItemText
        classes={{ primary: classes.primaryFeedItem, secondary: classes.secondaryFeedItem }}
        primary={<PrimaryListComponent item={item} includeMember={includeMember} />}
        secondary={<SubListComponent item={item} />}
      />
    </ListItem>
  )
}
