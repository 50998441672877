import { useQuery, gql } from '@apollo/client'
import { homepageTutorialVar } from '@/graphql/cache'

const GET_HOMEPAGE_TUTORIAL_TOGGLE = gql`
  query CurrentSortValue {
    homepageTutorialToggle @client
  }
`

export default () => {
  const { data } = useQuery(GET_HOMEPAGE_TUTORIAL_TOGGLE)
  // update
  const update = (val) => homepageTutorialVar(val)
  return [data.homepageTutorialToggle || false, update]
}
