import { Box, Grid, Link, Typography } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

/**
 * Displays the Disclaimer in the CDC footer
 * @component
 */
export default function Disclaimer() {
  return (
    <Box py={1}>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm='auto' xs={12}>
          <Link color='inherit' href='https://www.cdc.gov/Other/privacy.html' rel='noopener noreferrer' target='_blank'>
            <Typography variant='body2'>U.S. Department of Health & Human Services</Typography>
          </Link>
        </Grid>
        <Grid item sm='auto' xs={12}>
          <Link color='inherit' href='https://www.cdc.gov/Other/privacy.html' rel='noopener noreferrer' target='_blank'>
            <Typography variant='body2'>USA.gov</Typography>
          </Link>
        </Grid>
        <Grid item sm='auto' xs={12}>
          <Link color='inherit' href='https://www.cdc.gov/Other/privacy.html' rel='noopener noreferrer' target='_blank'>
            <Typography variant='body2'>
              CDC Website Exit Disclaimer <ExitToApp fontSize='inherit' />
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}
