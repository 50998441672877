import { gql } from '@apollo/client'

const topicByStatusQuery = gql`
  query topicsByStatusQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!, $policyType: String!) {
    nationalPolicyTopicByStatus(tags: $tags, startDate: $startDate, endDate: $endDate) {
      subjectName
      subjectId
      status
      statusCount
    }

    topLevelNationalPolicyKpisData(tags: $tags, startDate: $startDate, endDate: $endDate, policyType: $policyType) {
      policyCount
    }
  }
`

export { topicByStatusQuery }
