import PropTypes from 'prop-types'
import { InputBase, IconButton, InputLabel } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    fontWeight: theme.robotoLight,
    padding: '4px 26px 4px 12px',
    disabled: {
      color: '#fff'
    }
  }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  search: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(41,41,53,.75)',
    border: '1px solid #18181e',
    marginLeft: theme.spacing(2),
    display: 'flex'
  },
  iconButton: {
    color: 'white',
    padding: 0
  },
  closeIcon: {
    fontSize: '1rem'
  }
}))

/**
 * Simple Search Bar component used in the Top Supporters card.
 * @component
 */
export default function SimpleSearch({ onChange, value, placeholder, reset, ariaLabel }) {
  const classes = useStyles()
  return (
    <div className={classes.search}>
      {/* <InputLabel
        id={ariaLabel}
        style={{
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: 0,
          margin: -1,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          width: 0
        }}
      >
        {ariaLabel}
      </InputLabel> */}
      <CustomInput
        inputProps={{ 'aria-label': ariaLabel }}
        placeholder={placeholder}
        onChange={onChange}
        variant='outlined'
        value={value}
        endAdornment={
          value.length > 0 && (
            <IconButton aria-label='clear' classes={{ root: classes.iconButton }} onClick={reset}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          )
        }
      />
    </div>
  )
}

SimpleSearch.propTypes = {
  /**
   * Label for Aria in Drop Down
   */
  ariaLabel: PropTypes.string,
  /**
   * Callback function when menu item is selected.
   */
  onChange: PropTypes.func,
  /**
   * Placeholder string for text input
   */
  placeholder: PropTypes.string,
  /**
   * Callback function to reset input
   */
  reset: PropTypes.oneOfType([PropTypes.func]),
  /**
   * The input value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SimpleSearch.defaultProps = {
  ariaLabel: '',
  onChange: () => {},
  reset: () => {},
  placeholder: '',
  value: ''
}
