import { gql } from '@apollo/client'

const topLevelStatePolicyQuery = gql`
  query topLevelStatePolicyQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!, $policyType: String!) {
    topLevelStatePolicyKpisData(tags: $tags, startDate: $startDate, endDate: $endDate, policyType: $policyType) {
      policyCount
      billCount {
        dimension
        count
        name
      }
      resolutionCount {
        dimension
        count
        name
      }
      billTotalCount {
        count
        name
      }
      resolutionTotalCount {
        count
        name
      }
      stateCount
      states {
        stateName
      }
    }
  }
`
export { topLevelStatePolicyQuery }
