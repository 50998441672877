import { useState } from 'react'
import PropTypes from 'prop-types'
import { isFuture } from 'date-fns'
import { FormGroup } from '@material-ui/core'

import { getStartOfToday, isOnOrBefore, isOnOrBetween } from '@/utils/datetime'
import { debounce } from '@/utils/helper'
import { DatePicker } from '.'

const FUTURE_DATE_MESSAGE = 'Date cannot be in the future'
const INVALID_DATE_MESSAGE = 'Please enter date in valid MM/DD/YYYY format'

const FROM_MIN_DATE_OUTSIDE_RANGE =
  'This date is before the start date you selected on the home page. There could potentially be no results.'
const FROM_MAX_DATE_OUTSIDE_RANGE =
  'This date is after the end date you selected on the home page. There will be no results.'
const FROM_MAX_DATE_MESSAGE = 'From date must be before Thru date'

const THRU_MAX_DATE_OUTSIDE_RANGE =
  'This date is after the end date you selected on the home page. There could potentially be no results.'
const THRU_MIN_DATE_OUTSIDE_RANGE =
  'This date is before the start date you selected on the home page. There will be no results.'
const THRU_MIN_DATE_MESSAGE = 'Thru date must be after From date'

/**
 * Date range bar for landing page / search screen.
 * @component
 */
export default function DateRange({
  autoOpen,
  fromDatePickerProps,
  thruDatePickerProps,
  onFromDateChange,
  onThruDateChange,
  onErrors,
  onNoErrors,
  minDate,
  maxDate,
  fromDateValue,
  thruDateValue
}) {
  const [thruDateOpen, setThruDateOpen] = useState(false)
  const [thruDateError, setThruDateError] = useState(false)
  const [fromDateError, setFromDateError] = useState(false)

  const startOfToday = getStartOfToday()
  const maximumDate = maxDate || startOfToday

  /**
   * Updates the 'From' date
   * @param {*} date
   */
  const handleFromDateChange = (date) => onFromDateChange(date)

  /**
   * Updates the 'Through' data
   * @param {*} date
   */
  const handleThruDateChange = (date) => onThruDateChange(date)

  // TODO: Both Error functions are being called, even if there initially not an error.
  //       Need to see why that is the case.

  /**
   * Handles errors for the 'From' data
   * @param {*} err - Error message
   * @param {*} d
   */
  const handleFromDateError = (err, d) => {
    const hasError = err !== ''
    const needsChange = hasError !== fromDateError

    if (needsChange) {
      setFromDateError(hasError)
      handleOnErrors(hasError || thruDateError)
    }
  }

  /**
   * Handles errors for the 'Through' data
   * @param {*} err - Error message
   * @param {*} d
   */
  const handleThruDateError = (err, d) => {
    const hasError = err !== ''
    const needsChange = hasError !== thruDateError

    if (needsChange) {
      setThruDateError(hasError)
      handleOnErrors(hasError || fromDateError)
    }
  }

  /**
   *  Clears errors for start date when the user chooses a
   *  date from the calendar popup. we only the end date picker
   *  after.
   *
   */
  const handleAcceptFromDate = () => {
    if (fromDateError) {
      setFromDateError(false)
      handleOnNoErrors()
    }

    if (autoOpen) {
      setThruDateOpen(true)
    }
  }

  /**
   * Clears error for "End Date" when the user chooses a
   * date from the calendar pop-up.
   *
   */
  const handleAcceptThruDate = () => {
    if (thruDateError) {
      setThruDateError(false)
      handleOnNoErrors()
    }

    setThruDateOpen(false)
  }

  /**
   * Function that is called if there is any errors
   *
   * @param {*} errors - Objects containing errors.
   */
  const handleOnErrors = (errors) => {
    if (onErrors) {
      onErrors(errors)
    }
  }

  // is called when the previous errors are gone
  const handleOnNoErrors = () => {
    if (onNoErrors) {
      onNoErrors()
    }
  }

  /**
   * Determines the from date picker's max date.
   * Logic:
   *   - If there is no selected thru date, then the max date allowed is the
   *     maximum date.
   *
   *   - If the selected thru date is valid and before the allowed maximum
   *     date, then thru date is the max date.
   *
   *   - If the selected date is invalid, then max date allowed is the
   *     maximum date
   *
   */
  const getFromMaxDate = () =>
    thruDateValue ? (isOnOrBefore(thruDateValue, maximumDate) ? thruDateValue : maximumDate) : maximumDate

  /**
   * Determines the from date's min date message
   */
  const getFromMinDateMessage = () => (isFuture(fromDateValue) ? FUTURE_DATE_MESSAGE : FROM_MIN_DATE_OUTSIDE_RANGE)

  /**
   * Determines the through/to date's max date message
   */
  const getFromMaxDateMessage = () =>
    isFuture(fromDateValue)
      ? FUTURE_DATE_MESSAGE
      : thruDateValue
      ? isOnOrBefore(thruDateValue, maximumDate)
        ? FROM_MAX_DATE_MESSAGE
        : FROM_MAX_DATE_OUTSIDE_RANGE
      : FROM_MAX_DATE_OUTSIDE_RANGE

  /**
   * Determines the through/to date's minimum date allowed.
   */
  const getThruMinDate = () =>
    fromDateValue ? (isOnOrBetween(fromDateValue, minDate, maximumDate) ? fromDateValue : minDate) : minDate

  /**
   * Determines the thru date's min date message
   */
  const getThruMinDateMessage = () =>
    isFuture(thruDateValue)
      ? FUTURE_DATE_MESSAGE
      : fromDateValue
      ? isOnOrBetween(fromDateValue, minDate, maximumDate)
        ? THRU_MIN_DATE_MESSAGE
        : THRU_MIN_DATE_OUTSIDE_RANGE
      : THRU_MIN_DATE_OUTSIDE_RANGE

  /**
   * Determines the thru date's maxdate message
   */
  const getThruMaxDateMessage = () => (isFuture(thruDateValue) ? FUTURE_DATE_MESSAGE : THRU_MAX_DATE_OUTSIDE_RANGE)

  // used by both pickers
  const commonPickerProps = {
    inputVariant: 'standard',
    placeholder: 'MM/DD/YYYY',
    invalidDateMessage: INVALID_DATE_MESSAGE
  }

  // debounces the search
  const debouncedFromErrors = debounce((value) => {
    handleFromDateError(value)
  }, 400)

  const debouncedThruErrors = debounce((value) => {
    handleThruDateError(value)
  }, 400)

  const handleOnErrorWrapper = (debouncedErrors) => (err) => debouncedErrors(err)

  return (
    <FormGroup row>
      <DatePicker
        id='from-date-picker'
        initialFocusedDate={minDate}
        label='From'
        onError={handleOnErrorWrapper(debouncedFromErrors)}
        onAccept={handleAcceptFromDate}
        onDateChange={handleFromDateChange}
        maxDate={getFromMaxDate()}
        maxDateMessage={getFromMaxDateMessage()}
        minDate={minDate} // min date is always the minimum date allowed
        minDateMessage={getFromMinDateMessage()}
        selectedDate={fromDateValue}
        style={{ width: '47%', marginRight: '6%', ...fromDatePickerProps.style }}
        {...commonPickerProps}
        {...fromDatePickerProps}
      />
      <DatePicker
        selectedDate={thruDateValue}
        onDateChange={handleThruDateChange}
        label='Thru'
        id='thru-date-picker'
        initialFocusedDate={maximumDate}
        onError={handleOnErrorWrapper(debouncedThruErrors)}
        onAccept={handleAcceptThruDate}
        minDate={getThruMinDate()}
        minDateMessage={getThruMinDateMessage()}
        maxDate={maximumDate} // max date is always the maximum date allowed
        maxDateMessage={getThruMaxDateMessage()}
        style={{ width: '45%', ...thruDatePickerProps.style }}
        open={thruDateOpen}
        onOpen={() => setThruDateOpen(true)}
        onClose={() => setThruDateOpen(false)}
        {...commonPickerProps}
        {...thruDatePickerProps}
      />
    </FormGroup>
  )
}

DateRange.propTypes = {
  fromDatePickerProps: PropTypes.shape({
    ...DatePicker.propTypes
  }),
  thruDatePickerProps: PropTypes.shape({
    ...DatePicker.propTypes
  }),
  onFromDateChange: PropTypes.func,
  onThruDateChange: PropTypes.func,
  onErrors: PropTypes.func,
  onNoErrors: PropTypes.func,
  autoOpen: PropTypes.bool,
  inputVariant: DatePicker.propTypes.inputVariant,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  fromDateValue: PropTypes.any,
  thruDateValue: PropTypes.any
}

DateRange.defaultProps = {
  fromDatePickerProps: {},
  thruDatePickerProps: {}
}
