/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react'

import { Button, Grid, Box, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { withRouter } from 'react-router-dom'
import { startOfYear, format, isFuture } from 'date-fns'

import Card from '@material-ui/core/Card'

import { DatePicker, HelpButton, HeaderBar } from '@/components'
import { useSenateHouseToggle, useSelectedKpi, useSortToggle, useSelectedTab, useMapFeedStatus } from '@/hooks'

import './Search.css'

const FUTURE_DATE_MESSAGE = 'Date cannot be in the future'
const START_DATE_MESSAGE = 'Start date must be before end date'
const END_DATE_MESSAGE = 'End date must be after start date'
const INVALID_DATE_MESSAGE = 'Please enter date in valid MM/DD/YYYY format'

const useStyles = makeStyles((theme) => ({
  searchInput: {
    color: 'white',
    fontSize: '1.2rem'
  },
  underline: {
    '&::before': {
      borderBottomColor: 'orange'
    }
  },
  searchLabel: {
    color: 'white',
    fontSize: '1.2rem'
  },
  searchContainer: {
    margin: '5em auto 3em',
    width: '33%'
  },
  toggleGroup: {
    width: '300'
  },
  toggleButtonLabel: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'rgb(41, 41, 53)'
  },
  toggleButtonColor: {
    color: '#ffffff'
  },
  toggleButtonSelected: {
    color: '#ebbc2f'
  },
  toggleContainer: {
    margin: theme.spacing(8, 0)
  },
  cancerToggleGroup: {
    width: '30vw',
    margin: '0 auto 1em'
  },
  dateSelector: {
    verticalAlign: 'middle',
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  disabledSearch: {
    color: 'rgba(255, 255, 255, 0.30) !important',
    backgroundColor: '#808080a1 !important'
  },
  mainCard: {
    width: '70%',
    marginTop: 100,
    marginBottom: 100
  },
  card: {
    backgroundColor: 'rgb(41, 41, 53)',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingBottom: 30
  },
  root: {
    color: '#ffffff',
    '&.Mui-selected': {
      color: '#ebbc2f'
    }
  }
}))

const cancerButtons = [
  { key: 'All Cancer', value: 'cancer' },
  { key: 'Breast', value: 'breast' },
  { key: 'Cervical', value: 'cervical' },
  { key: 'Colorectal', value: 'colorectal' },
  { key: 'Gynecologic', value: 'gynecologic' },
  { key: 'Lung', value: 'lung' },
  { key: 'Prostate', value: 'prostate' },
  { key: 'Skin', value: 'skin' },
  { key: 'Uterine', value: 'uterine' }
]

const strategicButtons = [
  { key: 'Screening', value: 'screening' },
  { key: 'Cancer Survivors', value: 'survivor' }
]

const datePickerStyle = { width: 185, marginLeft: 20 }

function SearchLayout({ history }) {
  const [, updateSenateHouseToggle] = useSenateHouseToggle()
  const [, updateSelectedKpi] = useSelectedKpi()
  const [, updateSortToggle] = useSortToggle()
  const [, updateSelectedTab] = useSelectedTab()
  const [, updateMapFeedStatus] = useMapFeedStatus()

  const resetclientStates = () => {
    updateSenateHouseToggle('rep')
    updateSelectedKpi('Representatives')
    updateSortToggle('createdAt')
    updateSelectedTab(0)
    updateMapFeedStatus(false)
  }

  const classes = useStyles()
  const dateToday = new Date()

  const firstDayOfCurrentyear = startOfYear(dateToday)
  const selectedTags = []
  const [cancerTags, setCancerTags] = useState('')
  const [strategicTags, setStrategicTags] = useState('')
  const [sdohTags, setSdohTags] = useState(false)
  const [startDate, setStartDate] = useState(firstDayOfCurrentyear)
  const [endDate, setEndDate] = useState(dateToday)
  const [endDateError, setEndDateError] = useState(false)
  const [startDateError, setStartDateError] = useState(false)

  const dateFormat = 'yyyy-MM-dd'

  resetclientStates()

  const handleCancerTags = (event, tag) => setCancerTags(tag)

  const handleStategicTags = (event, tag) => {
    setStrategicTags(tag)
    if (cancerTags === '') setSdohTags('')
  }

  const handleSdohTags = (event, tag) => {
    setSdohTags(tag)
    if (cancerTags === '') setStrategicTags('')
  }

  const handleOnClick = () => {
    const base = process.env.PUBLIC_URL || ''
    if (cancerTags !== '' && cancerTags !== null) {
      selectedTags.push(cancerTags)
    } else if (strategicTags === '' && sdohTags === false) {
      selectedTags.push('cancer')
    }

    if (strategicTags !== '' && strategicTags !== null) {
      selectedTags.push(strategicTags)
    }

    if (sdohTags !== false) {
      selectedTags.push('sdoh')
    }

    const searchParams = {
      tags: selectedTags,
      startDate: format(startDate, dateFormat),
      endDate: format(endDate, dateFormat)
    }

    localStorage.setItem('searchParams', JSON.stringify(searchParams))
    updateSelectedTab(0)

    history.push(`${base}/national`)
  }

  // for some reason this is called even when there's no error.
  // check if err string is not empty, which means there's an
  // error. update error state so we can disable the search
  // button
  const handleStartDateError = (err, d) => {
    const hasError = err !== ''
    const needsChange = hasError !== startDateError

    if (needsChange) {
      setStartDateError(hasError)
    }
  }

  // for some reason this is called even when there's no error.
  // check if err string is not empty, which means there's an
  // error. update error state so we can disable the search
  // button.
  const handleEndDateError = (err, d) => {
    const hasError = err !== ''
    const needsChange = hasError !== endDateError

    if (needsChange) {
      setEndDateError(hasError)
    }
  }

  // Clears errors for start date when the user chooses a
  // date from the calendar popup. we only the end date picker
  // after.
  const handleAcceptStartDate = () => {
    if (startDateError) {
      setStartDateError(false)
    }
  }

  // Clears errors for end date when it's accepted
  const handleAcceptEndDate = () => {
    if (endDateError) {
      setEndDateError(false)
    }
  }

  const btnGroup = (key, value, tags, btnColor, onChange) => (
    <ToggleButtonGroup key={key} exclusive className={classes.toggleGroup} value={tags} onChange={onChange}>
      <ToggleButton
        style={{
          borderRadius: 7,
          border: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          height: 40,
          margin: 6,
          backgroundColor: 'rgb(41, 41, 53)'
        }}
        classes={{
          root: classes.root,
          selected: classes.toggleButtonSelected,
          label: classes.toggleButtonLabel
        }}
        key={key}
        value={value}
      >
        {key}
      </ToggleButton>
    </ToggleButtonGroup>
  )

  return (
    <Grid container className={classes.mainCard} justifyContent='center' role='main'>
      <Grid item xs={12} style={{ minWidth: '400px' }}>
        <Card className={classes.card} raised>
          <Grid container direction='row'>
            <Grid item xs={12}>
              <HeaderBar />
            </Grid>
            <form style={{ width: '100%' }}>
              <Grid
                item
                xs={12}
                id='filterButtons'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <div className='form-group'>
                  <label> Cancer </label>
                  <div className={classes.cancerToggleGroup} style={{ minWidth: '100%' }}>
                    {cancerButtons.map(({ key, value }) =>
                      btnGroup(key, value, cancerTags, 'cancerColor', handleCancerTags)
                    )}
                  </div>
                </div>
                <div className='form-group'>
                  <label> Topic </label>
                  <div className={classes.cancerToggleGroup} style={{ minWidth: '100%' }}>
                    {strategicButtons.map(({ key, value }) =>
                      btnGroup(key, value, strategicTags, 'strategicColor', handleStategicTags)
                    )}
                  </div>
                </div>
                <Box>
                  <FormControlLabel
                    label='Include results with Social Determinants of Health'
                    control={
                      <Checkbox
                        name='sdoh checkbox'
                        checked={sdohTags}
                        onChange={handleSdohTags}
                        style={{ color: 'white' }}
                        inputProps={{
                          'aria-label': 'include results with social determinants of health'
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.dateSelector} id='date-pickers'>
                <DatePicker
                  onAccept={handleAcceptStartDate}
                  onDateChange={(date) => setStartDate(date)}
                  onError={handleStartDateError}
                  invalidDateMessage={INVALID_DATE_MESSAGE}
                  label='Start date'
                  maxDate={endDate}
                  maxDateMessage={isFuture(startDate) ? FUTURE_DATE_MESSAGE : START_DATE_MESSAGE}
                  placeholder='MM/DD/YYYY'
                  selectedDate={startDate}
                  style={datePickerStyle}
                  id='start-date-picker'
                />
                <DatePicker
                  onAccept={handleAcceptEndDate}
                  onDateChange={setEndDate}
                  onError={handleEndDateError}
                  invalidDateMessage={INVALID_DATE_MESSAGE}
                  label='End date'
                  maxDateMessage={FUTURE_DATE_MESSAGE}
                  minDate={startDate}
                  minDateMessage={END_DATE_MESSAGE}
                  placeholder='MM/DD/YYYY'
                  selectedDate={endDate}
                  style={datePickerStyle}
                  id='end-date-picker'
                />
                <Button
                  id='searchBtn'
                  classes={{ disabled: classes.disabledSearch }}
                  variant='contained'
                  onClick={() => handleOnClick()}
                  style={{
                    height: 42,
                    width: 120,
                    backgroundColor: '#ebbc2f',
                    color: '#292935',
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    marginLeft: '2rem',
                    marginBottom: '18px'
                  }}
                  disabled={startDateError || endDateError}
                >
                  Search
                </Button>

                <HelpButton isHomeTut={true} />
              </Grid>
            </form>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default withRouter(SearchLayout)
