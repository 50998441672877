import PropTypes from 'prop-types'
import { DataUnavailable, DotPlot } from '@/components'
import { formatDotPlot } from '@/utils/formatNivo'

/**
 * Shows a dot plot for progress through congress section on the
 * National tab.
 */
export default function PolicyDotPlot({ historyData, totalPolicyItems }) {
  const history = historyData.slice()

  const sortedData = history.sort((a, b) => {
    const splitter = /^([A-Z]*)(\d+)/
    const aAlpha = a.billNumber.match(splitter)
    const bAlpha = b.billNumber.match(splitter)
    const anum = parseInt(aAlpha[2], 10)
    const bnum = parseInt(bAlpha[2], 10)
    return aAlpha[1] < bAlpha[1] ? -1 : aAlpha[1] > bAlpha[1] ? 1 : anum === bnum ? 0 : anum - bnum
  })

  const { data } = formatDotPlot(sortedData)

  return data.length === 0 ? <DataUnavailable /> : <DotPlot data={data} totalItems={totalPolicyItems} />
}

PolicyDotPlot.propTypes = {
  /**
   * List of policy items to show on dot plot
   */
  historyData: PropTypes.array,
  /**
   * total number of policy items
   */
  totalPolicyItems: PropTypes.number
}

PolicyDotPlot.defaultProps = {
  historyData: [],
  totalPolicyItems: 0
}
