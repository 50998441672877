import PropTypes from 'prop-types'
import { parseISO, isWithinInterval, parse, isValid } from 'date-fns'
import { DateRange } from '@/components'
import { getStartOfToday, format, getStartOfDate, isOnOrBefore, isOnOrAfter } from '@/utils/datetime'

const dateFormatString = 'MM/dd/yyyy'

const isDateValid = (date) => date && date !== 'Invalid Date' && isValid(date)

// Potential options to pass to the options property for the column using
// this date range filter. Can supply own instead.
export const getOptions = (minDate, maxDate, onError, onNoError, dateFormat = dateFormatString) => ({
  customFilterListOptions: {
    render: (list) => {
      let renderValues = []
      if (list.length) {
        const from = list[0],
          thru = list[1]

        if (isDateValid(from)) {
          renderValues.push(`From: ${format(from, dateFormat)}`)
        }

        if (isDateValid(thru)) {
          renderValues.push(`Thru: ${format(thru, dateFormat)}`)
        }
      }

      return renderValues
    },
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1)
      return filterList
    }
  },
  // this customizes how the filter looks in the modal,
  // and the logic for determining whether a row should
  // be filtered out.
  filterOptions: {
    names: [],
    fullWidth: true,
    logic: (date, filters) => {
      let shouldFilter = false

      if (filters.length) {
        const parsedDate = parse(date, dateFormatString, new Date())
        let from = filters[0],
          thru = filters[1]

        if (isDateValid(from) && isDateValid(thru)) {
          // if user chose both dates we check if the date is between them
          shouldFilter = !isWithinInterval(parsedDate, { start: getStartOfDate(from), end: getStartOfDate(thru) })
        } else if (!isDateValid(from)) {
          // if the user didn't choose the from date, we check if the date
          // is before the thru date
          shouldFilter = !isOnOrBefore(parsedDate, getStartOfDate(thru))
        } else if (!isDateValid(thru)) {
          // if the user didn't choose the thru date, we check if the date
          // is after the from date
          shouldFilter = !isOnOrAfter(parsedDate, getStartOfDate(from))
        }
      }
      return shouldFilter
    },
    display: (filterList, onChange, index, column) => (
      <DateRangeFilter
        minDate={parseISO(minDate)}
        maxDate={parseISO(maxDate)}
        onChange={onChange}
        index={index}
        column={column}
        filterList={filterList}
        onErrors={onError}
        onNoErrors={onNoError}
      />
    )
  }
})

export default function DateRangeFilter({
  minDate,
  maxDate,
  filterList,
  onChange,
  index,
  column,
  onErrors,
  onNoErrors
}) {
  const startOfToday = getStartOfToday()
  const maximumDate = maxDate || startOfToday
  let filters = filterList[index]

  // handles a date change
  const handleDateChange = (dateIndex) => (date) => {
    filters[dateIndex] = date
    onChange(filters, index, column)
  }

  return (
    <DateRange
      maxDate={maximumDate}
      minDate={minDate}
      onErrors={onErrors}
      onNoErrors={onNoErrors}
      onFromDateChange={handleDateChange(0)}
      onThruDateChange={handleDateChange(1)}
      fromDateValue={filterList[index][0] ? filterList[index][0] : null}
      thruDateValue={filterList[index][1] ? filterList[index][1] : null}
    />
  )
}

DateRangeFilter.propTypes = {
  /**
   * This comes directly from mui-datatables display() function.
   * Needs to be called when the input changes.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * This comes directly from mui-datatables display() function.
   * Contains the current list of filters
   */
  filterList: PropTypes.array.isRequired,
  /**
   * This comes directly from mui-datatables display() function.
   * The index of this input in the filterList array
   */
  index: PropTypes.number.isRequired,
  /**
   * This comes directly from mui-datatables display() function.
   * Includes information about the column
   */
  column: PropTypes.any.isRequired,
  /**
   * Minimum date the user can select
   */
  minDate: PropTypes.any,
  /**
   * Maximum date the user can select
   */
  maxDate: PropTypes.any,
  /**
   * Callback when the dates are not accepted by the date picker
   */
  onErrors: PropTypes.func,
  /**
   * Callback when the dates are accepted by the date picker
   */
  onNoErrors: PropTypes.func
}
