import { Button, Tooltip } from '@material-ui/core'
import { useResultsPageTutorial, useHomepageTutorial } from '@/hooks'
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'

/**
 * Shows a button for tutorial download.
 * @component
 */
export default function HelpButton({ isHomeTut }) {
  const [resultsPageOpen, updateResultPageTutorial] = useResultsPageTutorial()
  const [homePageOpen, updateHomepageTutorial] = useHomepageTutorial()
  const [isOpen, setIsOpen] = useState(false)
  const iconButtonRef = useRef(null)
  /**
   * Called when user clicks button. Removes hide tutorial item from local
   * storage if it exits, then turns on the tutorial.
   * @return {void}
   */
  const handleClick = () => {
    isHomeTut ? updateHomepageTutorial(true) : updateResultPageTutorial(true)
  }

  useEffect(() => {
    if (resultsPageOpen || homePageOpen) {
      setIsOpen(true)
    } else {
      if (isOpen) {
        document.getElementById('help_button').focus()
        setIsOpen(false)
      }
    }
  }, [homePageOpen, resultsPageOpen])

  const homeStyles = { marginBottom: 18, marginLeft: 10 }

  return (
    <Tooltip title='Start Tutorial'>
      <Button
        aria-label='Start Tutorial'
        onClick={handleClick}
        style={{ color: 'white', borderColor: 'white', ...(isHomeTut ? homeStyles : { marginRight: 10 }) }}
        ref={iconButtonRef}
        id='help_button'
        variant='outlined'
      >
        Help
      </Button>
    </Tooltip>
  )
}

HelpButton.propTypes = {
  /**
   * Determines which global state the help icon should update
   */
  isHomeTut: PropTypes.bool.isRequired,
  /**
   * Name of local storage object to be removed
   */
  localStorageText: PropTypes.string.isRequired
}
