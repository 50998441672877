import { Layer, Source } from 'react-map-gl'
import { find } from 'lodash'
import { useBasePath } from '@/hooks'

export default function ({ counts, range }) {
  const base = useBasePath()

  const generateFillColor = (stateCount) => {
    const foundRange = find(range, (rangeCount) => rangeCount.lower <= stateCount && rangeCount.upper >= stateCount)
    return foundRange ? foundRange.color : '#cccccc' // Grayscale 50%, gray50, grey50
  }

  const generateColorExpression = () => {
    const colors = {}

    counts.forEach((state) => {
      const { stateFips, count } = state
      const color = generateFillColor(count)
      if (!colors[color]) {
        colors[color] = []
      }
      colors[color].push(stateFips)
    })
    const colorExpression = ['match', ['get', 'GEOID']]
    Object.entries(colors).forEach(([color, GEOIDs]) => {
      colorExpression.push(GEOIDs, color)
    })

    colorExpression.push('rgb(204,204,204)') // No data color
    return colorExpression
  }

  return (
    <Source type='geojson' data={`${base}/geojson/2018_us_state.geojson`}>
      <Layer
        type='fill'
        id='state'
        source='geojson'
        paint={{
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.8],
          'fill-color': generateColorExpression()
        }}
      />
    </Source>
  )
}
