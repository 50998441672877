import { DataUnavailable, SankeyChart } from '@/components'

export default function TopicsSankey({ sankeyData }) {
  const { data, colors } = sankeyData

  return data.nodes.length === 0 ? (
    <DataUnavailable />
  ) : (
    <SankeyChart data={data} legends={[]} sort='descending' colors={colors} />
  )
}
