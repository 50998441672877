import HelpIcon from '@material-ui/icons/Help'
import MuiTooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    fixedHeader: {
      position: 'sticky',
      top: '0px',
      zIndex: 100,
      backgroundColor: theme.palette.background.paper
    },
    tooltip: {
      cursor: 'pointer'
    },
    mypopper: {
      '&[data-x-out-of-boundaries]': {
        display: 'none'
      }
    },
    data: {
      display: 'inline-block'
    },
    sortAction: {
      display: 'flex',
      verticalAlign: 'top',
      cursor: 'pointer'
    },
    dragCursor: {
      cursor: 'grab'
    },
    sortLabelRoot: {
      height: '10px'
    },
    sortActive: {
      color: theme.palette.text.primary
    },
    toolButton: {
      display: 'flex',
      outline: 'none',
      cursor: 'pointer'
    },
    hintIconAlone: {
      marginTop: '-3px',
      marginLeft: '3px'
    },
    hintIconWithSortIcon: {
      marginTop: '-3px'
    }
  }),
  { name: 'MUIDataTableHeadCell' }
)

const TableHeadCell = ({
  cellHeaderProps = {},
  children,
  colPosition,
  column,
  components = {},
  hint,
  index,
  options,
  print,
  setCellRef,
  sort,
  sortDirection,
  tableId,
  toggleSort
}) => {
  const [sortTooltipOpen, setSortTooltipOpen] = useState(false)
  const [hintTooltipOpen, setHintTooltipOpen] = useState(false)

  const classes = useStyles()

  const handleKeyboardSortInput = (e) => {
    if (e.key === 'Enter') {
      toggleSort(index)
    }

    return false
  }

  const handleSortClick = () => toggleSort(index)

  const { className, ...otherProps } = cellHeaderProps
  const Tooltip = components.Tooltip || MuiTooltip
  const sortActive = sortDirection !== 'none' && sortDirection !== undefined
  const ariaSortDirection = sortDirection === 'none' ? false : sortDirection

  const sortLabelProps = {
    classes: { root: classes.sortLabelRoot },
    active: sortActive,
    hideSortIcon: true,
    'aria-hidden': true,
    role: 'presentation',
    tabIndex: -1,
    ...(ariaSortDirection ? { direction: sortDirection } : {})
  }

  const cellClass = clsx({
    [classes.root]: true,
    [classes.fixedHeader]: options.fixedHeader,
    'datatables-noprint': !print,
    [className]: className
  })

  const showHintTooltip = () => {
    setSortTooltipOpen(false)
    setHintTooltipOpen(true)
  }

  const getTooltipTitle = () => {
    if (!options.textLabels) return ''
    return options.textLabels.body.columnHeaderTooltip
      ? options.textLabels.body.columnHeaderTooltip(column)
      : options.textLabels.body.toolTip
  }

  const closeTooltip = () => {
    setSortTooltipOpen(false)
  }

  return (
    <TableCell
      ref={(ref) => {
        setCellRef && setCellRef(index + 1, colPosition + 1, ref)
      }}
      className={cellClass}
      scope={'col'}
      sortDirection={ariaSortDirection}
      data-colindex={index}
      data-tableid={tableId}
      onMouseDown={closeTooltip}
      {...otherProps}
    >
      {options.sort && sort ? (
        <span
          role='button'
          onKeyUp={handleKeyboardSortInput}
          onClick={handleSortClick}
          className={classes.toolButton}
          data-testid={`headcol-${index}`}
          tabIndex={0}
          aria-label='Sort'
        >
          <Tooltip
            title={getTooltipTitle()}
            placement={'bottom-start'}
            open={sortTooltipOpen}
            onOpen={() => setSortTooltipOpen(true)}
            onClose={() => setSortTooltipOpen(false)}
            classes={{
              tooltip: classes.tooltip,
              popper: classes.mypopper
            }}
          >
            <div className={classes.sortAction}>
              <div
                className={clsx({
                  [classes.data]: true,
                  [classes.sortActive]: sortActive,
                  [classes.dragCursor]: false
                })}
              >
                {children}
              </div>
              <div className={classes.sortAction}>
                <TableSortLabel {...sortLabelProps} />
              </div>
            </div>
          </Tooltip>
          {hint && (
            <Tooltip title={hint}>
              <HelpIcon
                className={!sortActive ? classes.hintIconAlone : classes.hintIconWithSortIcon}
                fontSize='small'
              />
            </Tooltip>
          )}
        </span>
      ) : (
        <div className={hint ? classes.sortAction : null}>
          {children}
          {hint && (
            <Tooltip
              title={hint}
              placement={'bottom-end'}
              open={hintTooltipOpen}
              onOpen={() => showHintTooltip()}
              onClose={() => setHintTooltipOpen(false)}
              classes={{
                tooltip: classes.oltip,
                popper: classes.mypopperto
              }}
              enterDelay={300}
            >
              <HelpIcon className={classes.hintIconAlone} fontSize='small' />
            </Tooltip>
          )}
        </div>
      )}
    </TableCell>
  )
}

TableHeadCell.propTypes = {
  /** Options used to describe table */
  options: PropTypes.object.isRequired,
  /** Current sort direction */
  sortDirection: PropTypes.oneOf(['asc', 'desc', 'none']),
  /** Callback to trigger column sort */
  toggleSort: PropTypes.func.isRequired,
  /** Sort enabled / disabled for this column **/
  sort: PropTypes.bool.isRequired,
  /** Hint tooltip text */
  hint: PropTypes.string,
  /** Column displayed in print */
  print: PropTypes.bool.isRequired,
  /** Optional to be used with `textLabels.body.columnHeaderTooltip` */
  column: PropTypes.object,
  /** Injectable component structure **/
  components: PropTypes.object
}

export default TableHeadCell
