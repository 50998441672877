import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainKPIContainer: {
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'relative',
    margin: 'auto auto auto 1.2em'
  },
  statistic: theme.kpiStatistic,
  subtitle1: theme.kpiTitle,
  dataStory: theme.stateKpiDescription,
  filters: {
    margin: '0.9em 0 0 0'
  },
  stateLabel: {
    color: 'white',
    alignItems: 'center',
    fontSize: '0.9rem'
  }
}))

export default useStyles
