import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSearchParams } from '@/hooks'
import { searchedTags } from '@/utils/helper'
import { DataUnavailable } from '@/components'

const useStyles = makeStyles((theme) => ({
  mapKpiTitle: theme.kpiTitle,
  dataStory: theme.kpidescription,
  Kpi: theme.kpiStatistic
}))

/**
 * Shows the KPI information for the sponsor map
 * @component
 */
export default function KpiMap({ stateCount, congressCount, topState }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const tagNames = Object.keys(searchParams.tags)
  const searchedTerms = searchedTags(tagNames)

  return stateCount === 0 ? (
    <DataUnavailable />
  ) : (
    <>
      <Typography className={classes.Kpi}>{stateCount}</Typography>
      <Typography className={classes.mapKpiTitle} align='center'>
        REPRESENTED STATES
      </Typography>

      <Typography className={classes.dataStory}>
        {stateCount} states were represented by {congressCount} congress members sponsoring legislative items about{' '}
        <i>{searchedTerms}</i>. The most represented state was {topState.name} with {topState.count}{' '}
        {topState.count > 1 ? 'members' : 'member'}.
      </Typography>
    </>
  )
}
