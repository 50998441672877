import { FixedSizeList as List } from 'react-window'
import { makeStyles } from '@material-ui/core/styles'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'

import { useSenateHouseToggle } from '@/hooks'
import { DataUnavailable } from '@/components'

import AvatarRow from './AvatarRow'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'block',
    color: 'white',
    fontSize: '1rem',
    height: '1.3rem'
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    alignSelf: 'center'
  },
  avatarTile: {
    width: '95%',
    display: 'block',
    overflow: 'hidden',
    margin: '5px'
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedTile: {
    backgroundColor: '#5C5C76',
    borderRadius: '15px',
    width: '95%',
    display: 'block',
    overflow: 'hidden',
    margin: '5px'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  subtitleContainer: {
    marginTop: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export default function TopSupportersList({ data, onSelection, selected }) {
  const classes = useStyles()
  const [senateHouseToggle] = useSenateHouseToggle()
  const filteredData = data && data.filter((item) => item.type && item.type.includes(senateHouseToggle))
  const getItemData = memoize((classes, itemsPerRow, filteredData, onSelection, selected) => ({
    classes,
    itemsPerRow,
    filteredData,
    onSelection,
    selected
  }))

  const itemsPerRow = 3
  const rowCount = Math.ceil(filteredData.length / itemsPerRow)
  const itemData = getItemData(classes, itemsPerRow, filteredData, onSelection, selected)

  return filteredData.length === 0 ? (
    <DataUnavailable />
  ) : (
    <List height={300} itemCount={rowCount} itemData={itemData} itemSize={150} width='100%'>
      {AvatarRow}
    </List>
  )
}

TopSupportersList.propTypes = {
  data: PropTypes.array,
  onSelection: PropTypes.func,
  selected: PropTypes.shape({
    selectedBillMember: PropTypes.string
  })
}

TopSupportersList.defaultProps = {
  data: [],
  onSelected: null,
  selected: { selectedBillMember: '' }
}
