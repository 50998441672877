import ChoroplethLayer from './ChoroplethLayer'
import StateOutlineLayer from './StateOutlineLayer'

export default function Layers({ counts, colorScale, range }) {
  return (
    <>
      <ChoroplethLayer counts={counts} colorScale={colorScale} range={range} />
      <StateOutlineLayer />
    </>
  )
}
