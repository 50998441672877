import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import RemoveIcon from '@material-ui/icons/Remove'

const ExpandButton = ({
  areAllRowsExpanded,
  buttonClass,
  expandableRowsHeader,
  expandedRows,
  iconClass,
  iconIndeterminateClass,
  isHeaderCell,
  onExpand
}) => {
  return (
    <>
      {isHeaderCell && !areAllRowsExpanded() && areAllRowsExpanded && expandedRows.data.length > 0 ? (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          aria-label='Expand all table rows'
          disabled={expandableRowsHeader === false}
          className={buttonClass}
        >
          <RemoveIcon className={iconIndeterminateClass} />
        </IconButton>
      ) : (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}
          aria-label='Expand Table Row'
        >
          <KeyboardArrowRightIcon className={iconClass} />
        </IconButton>
      )}
    </>
  )
}

export default ExpandButton
