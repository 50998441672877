import { gql } from '@apollo/client'

const advocateMapQuery = gql`
  query advocateMapQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!) {
    topLevelBillKpisData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      congressBillCount
      congressBillStatus {
        status
        count
      }
      stateBillCount
    }

    billMapData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      stateFips
      count
      name
      billCount
    }

    billFeedWithState(tags: $tags, startDate: $startDate, endDate: $endDate) {
      billId
      billNumber
      description
      dimension
      state
      statusDate
      fullName
      url
    }
  }
`

export { advocateMapQuery }
