/* eslint-disable max-len */
import { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

/**
 * Error banner that sets the error if the user
 * is visiting the site from internet explorer.
 * @component
 */
export default function ErrorBanner() {
  const [isIE] = useState(window.document.documentMode)
  const [isError, setIsError] = useState(null)

  useEffect(() => {
    if (isIE) {
      setIsError({
        header: 'We have detected that you are using Internet Explorer.',
        content:
          'For the best experience, please use Chrome or Edge browsers. Some features may be limited on Internet Explorer.'
      })
    }
  }, [isIE])

  return (
    isError && (
      <div style={{ top: 0, wideth: '100w' }}>
        <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity='error'>
            <AlertTitle>{isError.header}</AlertTitle>
            {isError.content}
          </Alert>
        </Snackbar>
      </div>
    )
  )
}
