import { ResponsiveLine } from '@nivo/line'
import { format as form } from 'date-fns'
import { merge } from 'lodash'
import PropTypes from 'prop-types'
import { useRef, useEffect, useState } from 'react'

import PolicyItemsOvertimeTooltip from './PolicyItemsOvertimeTooltip'

/**
 * Line Chart Components for Cards uitlizing the
 * Nivo/Line library.
 * @component
 */
export default function LineChart({
  data,
  layers,
  margin,
  axisTop,
  axisRight,
  axisBottom,
  axisLeft,
  enableGridX,
  enableGridY,
  colors,
  enableArea,
  sliceTooltip,
  legends,
  min,
  max,
  width,
  height,
  theme,
  format,
  title
}) {
  const chartWrapper = useRef(null)
  const [marg, setMargin] = useState(0)
  console.log(colors)

  useEffect(() => {
    if (chartWrapper.current) {
      const el2 = chartWrapper.current
      let domElements
      domElements = el2.querySelectorAll("svg[role='img']")
      domElements.forEach((item) => {
        item.setAttribute('title', title)
      })
      setMargin(1)
    }
  }, [chartWrapper.current])

  return (
    <div style={{ width, height }} ref={chartWrapper}>
      <ResponsiveLine
        data={data}
        layers={layers}
        margin={margin}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min, max, stacked: false, reverse: false }}
        curve='basis'
        axisTop={axisTop}
        axisRight={axisRight}
        axisBottom={{
          ...axisBottom,
          tickValues: data[0].data.map((date) => form(new Date(date.x), 'MMM dd'))
        }}
        gridXValues={data[0].data.map((date) => form(new Date(date.x), 'MMM dd'))}
        axisLeft={merge(axisLeft, format)}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        colors={colors}
        pointSize={10}
        pointColor='#ffffff'
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        pointLabel='y'
        pointLabelYOffset={-12}
        enableArea={enableArea}
        areaBaselineValue={min}
        areaOpacity={0.3}
        enableSlices='x'
        sliceTooltip={sliceTooltip}
        legends={legends}
        enablePoints={false}
        theme={theme}
      />
    </div>
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.any,
  height: PropTypes.number,
  layers: PropTypes.array,
  margin: PropTypes.object,
  axisTop: PropTypes.object,
  axisRight: PropTypes.object,
  axisBottom: PropTypes.object,
  axisLeft: PropTypes.object,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  colors: PropTypes.array,
  enableArea: PropTypes.bool,
  sliceTooltip: PropTypes.func,
  legends: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.any,
  theme: PropTypes.object,
  format: PropTypes.object
}

LineChart.defaultProps = {
  data: [],
  width: '100%',
  height: 375,
  layers: ['grid', 'axes', 'areas', 'crosshair', 'slices', 'mesh', 'legends', 'lines', 'points', 'markers'],
  margin: { top: 50, right: 110, bottom: 85, left: 80 },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 90,
    legend: 'MONTHS',
    legendOffset: 36,
    legendPosition: 'middle'
  },
  axisLeft: {
    orient: 'left',
    tickSize: 7,
    tickPadding: 7,
    tickRotation: 0,
    legend: 'LEGISLATIVE ITEMS',
    legendOffset: -55,
    legendPosition: 'middle'
  },
  enableGridX: false,
  enableGridY: false,
  colors: ['#c7c7c7', '#ff6e59', '#104b6d'],
  enableArea: true,
  sliceTooltip: ({ slice }) => <PolicyItemsOvertimeTooltip points={slice.points} />,
  legends: [
    {
      anchor: 'top',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: -40,
      itemWidth: 100,
      itemHeight: 25,
      itemsSpacing: 4,
      symbolSize: 15,
      symbolShape: 'circle',
      itemDirection: 'left-to-right',
      itemTextColor: '#777',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
          }
        }
      ]
    }
  ],
  min: 0,
  max: 'auto',
  theme: {
    textColor: 'fff',
    tooltip: {
      container: {
        background: 'white',
        color: '#000'
      }
    },
    legends: {
      text: {
        fontSize: '.8rem',
        fontFamily: 'Roboto'
      }
    },
    axis: {
      legend: {
        text: {
          fill: '#ffffff',
          letterSpacing: '0.2rem',
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: '0.9rem'
        }
      },
      ticks: {
        text: {
          fill: '#ffffff',
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: '0.9rem'
        }
      }
    }
  },
  format: {}
}
