export default function CustomDot({ dot }) {
  return (
    <g>
      {dot.datum.actionCount !== 0 ? (
        <>
          <circle
            r={dot.size / 2}
            fill={dot.datum.lastItem ? '#20A281' : '#90BE6D'}
            style={{ cursor: 'pointer' }}
            onMouseEnter={dot.onMouseEnter}
            onMouseMove={dot.onMouseMove}
            onMouseLeave={dot.onMouseLeave}
            onClick={dot.onClick}
          />
          <text
            alignmentBaseline='central'
            textAnchor='middle'
            style={{
              fill: '#000000',
              fontFamily: 'Roboto',
              cursor: 'pointer',
              fontSize: '0.9rem',
              fontWeight: 'bold'
            }}
            onMouseEnter={dot.onMouseEnter}
            onMouseMove={dot.onMouseMove}
            onMouseLeave={dot.onMouseLeave}
          >
            {dot.datum.actionCount}
          </text>{' '}
        </>
      ) : null}
    </g>
  )
}
