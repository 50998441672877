import { startOfDay, startOfToday, format as formatDate, isEqual, isBefore, isAfter, isWithinInterval } from 'date-fns'

const getStartOfDate = (date) => startOfDay(new Date(date))

const getStartOfToday = () => startOfToday()

const format = (date, dateFormat) => formatDate(date, dateFormat)

const isOnOrBefore = (date, compareDate) =>
  isEqual(getStartOfDate(date), getStartOfDate(compareDate)) ||
  isBefore(getStartOfDate(date), getStartOfDate(compareDate))

const isOnOrAfter = (date, compareDate) => isEqual(date, compareDate) || isAfter(date, compareDate)

const isBetween = (date, start, end) => isWithinInterval(date, { start, end })

const isOnOrBetween = (date, start, end) => isOnOrBefore(date, end) && isOnOrAfter(date, start)

export { getStartOfToday, getStartOfDate, format, isOnOrBefore, isOnOrAfter, isBetween, isOnOrBetween }
