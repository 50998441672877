import { useState, useEffect } from 'react'
import { countBy, entries, flow, head, last, maxBy, partialRight as pR, filter, omit } from 'lodash'

import { useQuery } from '@apollo/client'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import { Card, SingleSelect, LoadingSpinner, ErrorMessage, KPI, PolicyMap, DataUnavailable } from '@/components'
import { stateMapCardQuery } from '@/graphql/state/'
import { useMapFeedStatus, useSearchParams } from '@/hooks'
import { BillStatusEnum, stateInfoContentData, typeOptions, PolicyEnum, FeedType } from '@/utils/constants'
import { aggregateData, billSwitch } from '@/utils/helper'
import { StateKpiMap } from './components'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    paddingRight: '10px',
    paddingTop: '1rem'
  },
  Kpi: {
    color: '#FFFFFF',
    fontSize: '4rem',
    textAlign: 'center'
  },
  mapKpiTitle: theme.kpiTitle,
  mapKpiContainer: {
    verticalAlign: 'center',
    paddingBottom: '0.9em',
    margin: 'auto auto auto 1.2em'
  },
  kpiDivider: {
    backgroundColor: '#FFFFFF'
  },
  dataStory: theme.kpidescription,
  chartTitle: {
    color: '#fff',
    marginRight: '.8rem',
    letterSpacing: '0.2em'
  },
  chartMenuContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  filterGroup: {
    flexGrow: 1
  },
  filters: {
    margin: '0 .8rem'
  }
}))

/**
 * State Map Card
 * Show casing Represented States and Territories
 * sponsoriing legislative items on the search term.
 * @component
 */
export default function StateMapCard() {
  const classes = useStyles()
  const [, updateFeedStatus] = useMapFeedStatus()

  // State Variables for breaking down data query.
  const [KPIData, setKPIData] = useState(null)
  const [billMapData, setBillMapData] = useState(null)
  const [downloadData, setDownloadData] = useState(null)
  const [feedData, setFeedData] = useState(null)
  const [allData, setAllData] = useState([])

  // State Variables based on aggregating billMapData
  const [highestPolicyCount, setHighestPolicyCount] = useState(null)
  const [topStates, setTopStates] = useState(null)

  // State Variables based on State Policy Status or Filter Status
  const [maxStatus, setMaxStatus] = useState(null)
  const [statusOptions, setStatusOptions] = useState(null)
  const [aggregatedData, setAggregatedData] = useState(null)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [filterState, setFilterState] = useState({ selectedType: 'ALL', selectedStatus: 'All' })
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  // Variables for useQuery
  const { tags, startDate, endDate } = useSearchParams()
  const policyType = PolicyEnum.State

  /**
   * Function that maps data to be donwloaded as csv
   * @function
   * @param {Object[]} data - Array of objects to be downloaded in a CSV format
   */
  const downloadCSV = (data) =>
    data.map((x) => {
      const a = omit(x, ['status', 'billType', 'stateFips'])
      const billType = billSwitch(x.billType)

      return {
        status: BillStatusEnum[x.status],
        billType: billType,
        ...a
      }
    })

  const { loading, error } = useQuery(stateMapCardQuery, {
    variables: {
      tags,
      startDate,
      endDate,
      policyType
    },
    onCompleted: ({
      topLevelStatePolicyKpisData: kpi,
      statebillMapData: billMapData,
      statePolicyStatusBreakDown: statBreakdown,
      stateMapFeedData: mapFeed
    }) => {
      setKPIData(kpi)
      setBillMapData(billMapData)
      setFeedData(mapFeed)

      if (kpi.policyCount === 0) {
        setIsDataEmpty(true)
        setAllData([])
        setMaxStatus(null)
        setHighestPolicyCount(null)
        setTopStates(null)
        setStatusOptions(null)
        setAggregatedData(null)
        setDownloadData([])
        setHasLoaded(true)
      } else {
        setIsDataEmpty(false)
        const aggData = aggregateData(billMapData, 'name')
        const highestCount = Math.max(...aggData.map((o) => o.count))
        const statesTop = aggData.filter((item) => item.count === highestCount)
        const statOptions = [{ id: 0, value: 'All', label: 'All Statuses' }]
        const statMax = flow(countBy, entries, pR(maxBy, last), head)(statBreakdown.map((t) => t.dimension))

        let index = 1

        for (const item of statBreakdown) {
          const status = {
            id: index,
            value: item.dimension,
            label: BillStatusEnum[item.dimension]
          }
          statOptions.push(status)
          index += 1
        }

        setAllData(mapFeed)
        setMaxStatus(statMax)
        setHighestPolicyCount(highestCount)
        setTopStates(statesTop)
        setStatusOptions(statOptions)
        setAggregatedData(aggData)
        const csvData = downloadCSV(billMapData)

        setDownloadData([...csvData])
        setHasLoaded(true)
      }
    }
  })

  useEffect(() => {
    // filters data based on selected policy type in the dropdown
    const filterByPolicy = (data) => {
      const selectedPolicyType = filterState.selectedType
      const filterByPolicyType = filter(data, (o) => {
        if (selectedPolicyType === 'ALL') return data

        if (selectedPolicyType === 'B') {
          return o.billType === 'B'
        }
        if (selectedPolicyType === 'R') {
          return o.billType === 'R' || o.billType === 'JR' || o.billType === 'CR'
        }
      })
      return filterByPolicyType
    }

    // filters data based on selected status in the dropdown
    const filterByStatus = (data) => {
      const selectedStatus = filterState.selectedStatus
      const filterDataByStatus = filter(data, (o) => {
        if (selectedStatus === 'All') return data
        return o.status === selectedStatus
      })

      return filterDataByStatus
    }

    if (billMapData) {
      const { selectedType: type, selectedStatus: status } = filterState

      const a = filter(billMapData, (o) => {
        if (type === 'ALL') return billMapData
        else if (type === 'B') return o.billType === 'B'
        else return o.billType === 'R' || o.billType === 'JR' || o.billType === 'CR'
      })

      const f = filter(a, (o) => (status === 'All' ? a : o.status === status))
      const csvData = downloadCSV(f)

      setDownloadData([...csvData])
      setAggregatedData(aggregateData(f, 'name'))
      setFeedData(filterByStatus(filterByPolicy(allData)))
    }
  }, [filterState])

  const infoData = [
    {
      title: 'Which states have relevant legislative items?',
      description: [
        'This map shows the states with legislative items that match your search choices. Click on a state or Washington DC to see a list of legislative items in that state. You may need to click the minus sign above the map to zoom out to see Alaska and Hawaii.'
      ],
      dimension: ['[Color] Corresponds to the number of legislative items that match your search choices'],
      note: null
    }
  ]

  /**
   * Handles when the user changes the policy type or status dropdowns
   * @param {Object} e event
   */
  const handleChange = (e) => {
    setFilterState({
      ...filterState,
      [e.target.name]: e.target.value
    })
    updateFeedStatus(false)
  }

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={Boolean(error) || loading || !hasLoaded}
      fileName='StateMap'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {hasLoaded && !error && !isDataEmpty && (
        <>
          <KPI>
            <StateKpiMap
              policyCount={KPIData.policyCount}
              stateCount={KPIData.stateCount}
              topState={topStates}
              topStatus={maxStatus}
              topStatePolicyCount={highestPolicyCount}
            />
          </KPI>
          <Grid style={{ flex: '1 1 65%', padding: 20 }}>
            <div className={classes.chartMenuContainer}>
              <Typography variant='h5' className={classes.chartTitle}>
                LEGISLATIVE ITEMS BY STATE
              </Typography>
              <div className={classes.filterGroup}>
                <span className={classes.filters}>
                  <SingleSelect
                    name='selectedType'
                    onChange={handleChange}
                    options={typeOptions}
                    value={filterState.selectedType}
                    ariaLabel='Select type of policy to filter map'
                    id='select_policy_type'
                  />
                </span>
                <span className={classes.filters}>
                  <SingleSelect
                    name='selectedStatus'
                    onChange={handleChange}
                    options={statusOptions}
                    value={filterState.selectedStatus}
                    ariaLabel='Select policy status to filter map'
                    id='select_policy_status'
                  />
                </span>
              </div>
            </div>
            <div className={classes.mapContainer}>
              <PolicyMap
                counts={aggregatedData}
                countType='Legislative Items'
                feed={feedData}
                feedType={FeedType.STATE_BILL}
                title='LEGISLATIVE ITEMS'
              />
            </div>
          </Grid>
        </>
      )}
    </Card>
  )
}
