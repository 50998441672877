import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import moment from 'moment'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { searchedTags } from '@/utils/helper'
import { cancerTagValues, strategicTagValues } from '@/utils/constants'

const useStyles = makeStyles({
  keyword: {
    color: 'white',
    fontSize: '1.8rem'
  },
  returnDetails: {
    color: '#B8B8B8',
    fontSize: '1.4rem',
    margin: '1.5rem auto',
    maxWidth: '60%',
    textAlign: 'center'
  },
  highlight: {
    color: 'white',
    fontWeight: 'bold'
  },
  termsGroup: {
    backgroundColor: 'transparent',
    position: 'relative',
    marginBottom: 10
  },
  toggleButtonLabel: {
    color: 'white',
    textTransform: 'none'
  },
  cancerButton: {
    color: 'white',
    // backgroundColor: 'rgba(220,20,60,0.1)',
    fontSize: '1.3rem'
  },
  sdohButton: {
    color: 'white',
    // backgroundColor: 'rgba(235, 188, 47,0.1)',
    fontSize: '1.3rem'
  },
  strategicButton: {
    color: 'white',
    // backgroundColor: 'rgba(16, 192, 159,0.1)',
    fontSize: '1.3rem'
  },
  buttonContainer: {
    width: '100%',
    marginTop: '2em'
  }
})

/**
 * Shows the list of search terms and search date
 * @component
 */
export default function SearchResultsHeader({ searchParams }) {
  const classes = useStyles()
  const { tags, startDate, endDate } = searchParams
  const tagNameObjects = Object.keys(tags)
  const tagNames = []

  for (const item of tagNameObjects) {
    if (item === 'sdoh') tagNames.push('Social Determinants of Health')
    else tagNames.push(item.charAt(0).toUpperCase() + item.slice(1))
  }

  const searchedTerms = searchedTags(tagNameObjects)
  const getSearchTagColor = (searchTag) => {
    if (cancerTagValues.includes(searchTag)) {
      return classes.cancerButton
    }
    if (strategicTagValues.includes(searchTag)) {
      return classes.strategicButton
    } else {
      return classes.sdohButton
    }
  }

  return (
    <Grid container alignItems='center' justifyContent='center'>
      {/* <Grid item id='backToSearch'>
        <BackToSearch />
      </Grid> */}
      <Grid item id='searchedItems'>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <Typography className={classes.returnDetails}>
              Displaying results for{' '}
              <span>
                {searchedTerms.map((term, i) => (
                  <Fragment key={`${term}-term`}>
                    <span className={classes.highlight}>
                      <i>{term}</i>
                    </span>
                    {i !== searchedTerms.length - 1 && <span> and </span>}
                  </Fragment>
                ))}
              </span>{' '}
              for the period of{' '}
              <span className={classes.highlight}>{moment.utc(startDate).format('MMMM D, YYYY')}</span> to{' '}
              <span className={classes.highlight}>{moment.utc(endDate).format('MMMM D, YYYY')}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item>
        <div className={classes.buttonDiv}>
          <HelpButton localStorageText={HIDE_RESULTS_PAGE_TUTORIAL} isHomeTut={false} />
          <Glossary className={classes.glossaryIcon} />
        </div>
      </Grid> */}
    </Grid>
  )
}

SearchResultsHeader.propTypes = {
  /**
   * List of selected terms and dates from the landing page
   */
  searchParams: PropTypes.object.isRequired
}
