import { Grid, Link, Typography } from '@material-ui/core'
import { MailOutline, Phone, Schedule, WebAsset } from '@material-ui/icons'

import { FooterSection } from '.'

/**
 * Component to show the Questions section of hte CDC footer
 * @component
 */
export default function Questions() {
  return (
    <FooterSection title='HAVE QUESTIONS?'>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Link
            color='inherit'
            href='https://www.cdc.gov/cdc-info/index.html'
            rel='noopener noreferrer'
            target='_blank'
          >
            <Typography variant='body2'>
              <WebAsset fontSize='medium' style={{ verticalAlign: 'middle' }} /> Visit CDC-INFO
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            <Phone fontSize='medium' style={{ verticalAlign: 'middle' }} /> Call 800-232-4636
          </Typography>
        </Grid>
        <Grid item>
          <Link
            color='inherit'
            href='https://wwwn.cdc.gov/dcs/contactus/form'
            rel='noopener noreferrer'
            target='_blank'
          >
            <Typography variant='body2'>
              <MailOutline fontSize='medium' style={{ verticalAlign: 'middle' }} /> Email CDC-INFO
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link
            color='inherit'
            href='https://www.cdc.gov/cdc-info/index.html'
            rel='noopener noreferrer'
            target='_blank'
          >
            <Typography variant='body2'>
              <Schedule fontSize='medium' style={{ verticalAlign: 'middle' }} /> Open 24/7
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </FooterSection>
  )
}
