import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { cache, gqlErrorVar } from './cache'

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers
  }
}))

const httpLink = createHttpLink({ uri: process.env.REACT_APP_GQL_API_URL_BASE })

/**
 * Combines errors into one string
 * @param {Array} gqlErrors
 * @returns combined string
 */
const getCombinedMessage = (gqlErrors) =>
  gqlErrors.length > 0 ? gqlErrors.reduce((message, error) => message.concat('; ', error.message), '').slice(2) : ''

/**
 * Error handler for graphql
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors || networkError) {
    gqlErrorVar({
      hasError: true,
      message: getCombinedMessage(graphQLErrors || networkError)
    })

    if (graphQLErrors) {
      console.log({ graphQLErrors })
    } else {
      console.log({ networkError })
    }
  }
})

export default new ApolloClient({ link: from([errorLink, authLink, httpLink]), cache })
