import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tooltip: {
    position: 'absolute',
    margin: '8px',
    padding: '4px',
    background: 'rgba(34, 34, 44, 0.92)',
    borderStyle: 'solid',
    borderWidth: '1px',
    border: 'rgb(24,24,30)',
    boxShadow: '0 4px 8px 0 rgba(64,64,64, 0.2), 0 6px 20px 0 rgba(64,64,64, 0.19);',
    borderRadius: '2px',
    color: 'white',
    maxWidth: '300px',
    fontSize: '10px',
    zIndex: 9,
    pointerEvents: 'none'
  }
}))

export default function HoverTooltip({ hoveredFeature, x, y, items, noItemsText, children }) {
  const classes = useStyles()

  if (hoveredFeature) {
    const { GEOID, NAME } = hoveredFeature.properties
    const tooltipInfo = items.filter((item) => item.stateFips && item.stateFips.includes(GEOID))
    const show = tooltipInfo.length > 0

    if (show) {
      const tooltip = tooltipInfo[0]
      const { name } = tooltip

      const tooltipChildren = React.Children.map(children, (child) => {
        const props = { tooltip }
        if (React.isValidElement(child)) {
          return React.cloneElement(child, props)
        }
        return child
      })

      return (
        <div className={classes.tooltip} style={{ left: x, top: y }}>
          <div>
            <b>{name}</b>
          </div>
          <div>{tooltipChildren}</div>
        </div>
      )
    }

    return (
      <div className={classes.tooltip} style={{ left: x, top: y }}>
        <div>
          <b>{NAME}</b>
        </div>
        <div>{noItemsText}</div>
      </div>
    )
  }

  return null
}
