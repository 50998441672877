import { Grid, Link, Typography } from '@material-ui/core'

/**
 * Component to show the Information section of the CDC Footer
 * @component
 */
export default function Information() {
  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Typography variant='body2' style={{ fontWeight: 700 }}>
          CDC INFORMATION
        </Typography>
      </Grid>

      <Grid item>
        <Link color='inherit' href='https://www.cdc.gov/about/default.htm' rel='noopener noreferrer'>
          <Typography variant='body2'>About CDC</Typography>
        </Link>
      </Grid>

      <Grid item>
        <Link color='inherit' href='https://jobs.cdc.gov/' rel='noopener noreferrer' target='_blank'>
          <Typography variant='body2'>Jobs</Typography>
        </Link>
      </Grid>

      <Grid item>
        <Link color='inherit' href='https://www.cdc.gov/funding/' rel='noopener noreferrer' target='_blank'>
          <Typography variant='body2'>Funding</Typography>
        </Link>
      </Grid>

      <Grid item>
        <Link
          color='inherit'
          href='https://www.cdc.gov/other/plugins/index.html'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Typography variant='body2'>File View & Players</Typography>
        </Link>
      </Grid>

      <Grid item>
        <Link color='inherit' href='https://www.cdc.gov/Other/policies.html' rel='noopener noreferrer' target='_blank'>
          <Typography variant='body2'>Policies</Typography>
        </Link>
      </Grid>
    </Grid>
  )
}
