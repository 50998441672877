import { ButtonBase, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { BadgeAvatar } from '@/components'

/**
 * Displays a member of congress row for the supporters list
 * @component
 */
export default function AvatarRow({ data, index, style }) {
  const { classes, itemsPerRow, filteredData, onSelection, selected } = data
  const { selectedBillMember } = selected

  const items = []
  const fromIndex = index * itemsPerRow
  const toIndex = Math.min(fromIndex + itemsPerRow, filteredData.length)

  const determineAvatarClasss = (id) => (selectedBillMember === id ? classes.selectedTile : classes.avatarTile)

  for (let i = fromIndex; i < toIndex; i++) {
    const { fullName, bioguideId, committee, screenName, count, stateName, govtrackUrl, opensecretsId } =
      filteredData[i]

    const tooltipData = {
      committee,
      fullName,
      govtrackUrl
    }

    const pref = bioguideId[0]
    const avatarSource = `https://bioguide.congress.gov/bioguide/photo/${pref}/${bioguideId}.jpg`

    items.push(
      <ButtonBase
        key={bioguideId}
        focusRipple
        onClick={() => onSelection(screenName, opensecretsId)}
        className={determineAvatarClasss(opensecretsId)}
      >
        <div className={classes.avatarContainer}>
          <BadgeAvatar altext={fullName} source={avatarSource} badge={Boolean(committee)} tooltipData={tooltipData} />
        </div>
        <div className={classes.subtitleContainer}>
          <Typography noWrap classes={{ noWrap: classes.subtitle }}>
            {fullName}
          </Typography>
          <Typography classes={{ root: classes.subtitle }}>{stateName}</Typography>
          <Typography classes={{ root: classes.subtitle }}>
            <b>{count}</b> {count > 1 ? 'items' : 'item'}
          </Typography>
        </div>
      </ButtonBase>
    )
  }

  return (
    <div className={classes.row} style={style}>
      {items}
    </div>
  )
}

AvatarRow.propTypes = {
  /**
   * This contains information for this row of data in the list.
   */
  data: PropTypes.object.isRequired,
  /**
   * This should be the index of this row in the list
   */
  index: PropTypes.number.isRequired,
  /**
   * Styles to apply to this row
   */
  style: PropTypes.object
}

AvatarRow.defaultProps = {
  style: {}
}
