import Helmet from 'react-helmet'
import { isMobile } from 'react-device-detect'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Tutorial, MobilePopup, HeaderCDC, Footer } from '@/components'
import { useHomepageTutorial } from '@/hooks'
import './Default.css'

export default function Default({ children }) {
  const [homePageOpen] = useHomepageTutorial()

  return (
    <>
      {isMobile ? <MobilePopup /> : <Tutorial isHomeTut />}
      <Helmet
        title='Cancer Issue & Policy Scanner | Division of Cancer Prevention & Control'
        meta={[
          { charset: 'utf-8' },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }
        ]}
      />
      <HeaderCDC hidden={homePageOpen} />
      <div className='App' aria-hidden={homePageOpen}>
        {children}
      </div>
      <Footer hidden={homePageOpen} />
    </>
  )
}
