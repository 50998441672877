import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, InputBase, InputLabel } from '@material-ui/core'
import PropTypes from 'prop-types'

const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(41,41,53,.75)',
    border: '1px solid #18181e',
    fontSize: 17,
    fontWeight: theme.robotoLight,
    padding: '4px 26px 4px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    disabled: {
      color: '#fff'
    }
  }
}))(InputBase)

const useStyles = makeStyles({
  arrow: {
    color: '#fff'
  }
})

/**
 * Single Select components used in multiple components in both
 * National and State cards.
 * @component
 */
export default function SingleSelect({ options, onChange, value, ariaLabel, name, id }) {
  const classes = useStyles()
  return (
    <>
      <InputLabel
        style={{
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: 0,
          margin: -1,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          width: 0
        }}
      >
        {ariaLabel}
      </InputLabel>
      <Select
        id={id}
        labelWidth={0}
        classes={{ icon: classes.arrow }}
        value={value}
        onChange={onChange}
        inputProps={{ name, 'aria-label': ariaLabel }}
        input={<CustomInput />}
        MenuProps={{ disableScrollLock: true }}
      >
        {options.map((item) => {
          const { id, value, label } = item
          return (
            <MenuItem key={id} value={value}>
              {label}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}
SingleSelect.propTypes = {
  /**
   * Label for Aria in Drop Down
   */
  ariaLabel: PropTypes.string,
  /**
   * Callback function when menu item is selected.
   */
  onChange: PropTypes.func,
  /**
   * Options array to populate drop down.
   */
  options: PropTypes.array,
  /**
   * The input value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
SingleSelect.defaultProps = {
  ariaLabel: '',
  onChange: () => {},
  options: [],
  value: ''
}
