import { Badge, Avatar, Tooltip } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClinicMedical } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { BadgeTooltip } from '@/components'

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    alignSelf: 'center'
  },
  tooltip: theme.supportersTooltip,
  tooltipArrow: theme.tooltipArrow
}))

const SmallBadge = withStyles({
  root: {
    width: 22,
    height: 22,
    backgroundColor: 'transparent'
  }
})(Avatar)

/**
 * Shows an image avatar badge with a tooltip, if badge is true it shows a little
 * house icon on top of the image.
 * @component
 */
export default function BadgeAvatar({ altext, source, badge, tooltipData }) {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      interactive
      arrow
      disableFocusListener
      disableTouchListener
      placement='top'
      title={<BadgeTooltip data={tooltipData} />}
    >
      {badge ? (
        <Badge
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          badgeContent={
            <SmallBadge variant='square'>
              <FontAwesomeIcon color='RGB(75, 227, 172)' icon={faClinicMedical} />
            </SmallBadge>
          }
        >
          <Avatar alt={altext} className={classes.small} src={source} />
        </Badge>
      ) : (
        <Avatar alt={altext} className={classes.small} src={source} />
      )}
    </Tooltip>
  )
}

BadgeAvatar.propTypes = {
  /**
   * alt text of the avatar image
   */
  alttext: PropTypes.string,
  /**
   * if true, this shows a little house icon on the avatar
   */
  badge: PropTypes.bool,
  /**
   * avatar image source
   */
  source: PropTypes.string,
  /**
   * information to show on the avatar tooltip when hovered over
   */
  tooltipData: PropTypes.object
}

BadgeAvatar.defaultProps = {
  alttext: '',
  badge: false,
  source: '',
  tooltipData: {}
}
