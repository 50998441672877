import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  colorKey: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    borderStyle: 'hidden',
    borderWidth: '0.075rem',
    borderColor: '#191A1A',
    borderRadius: '4px',
    overflow: 'hidden',
    width: 18,
    marginRight: '0.255rem',
    marginBottom: '0.255rem',
    fontFamily: 'Roboto'
  },
  colorLabel: {
    fontSize: '.9rem',
    fontFamily: 'Roboto'
  },
  legend: {
    display: 'flex'
  },
  legendTitle: {
    fontWeight: 300,
    fontSize: '.9rem',
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginBottom: 5
  }
}))

export default function MapLegend({ countType, range }) {
  const classes = useStyles()
  const zero = {
    color: '#cccccc',
    value: '0'
  }

  const legend = [zero, ...range]

  return (
    <div>
      <div className={classes.legendTitle}>{countType}</div>
      {legend.map((step) => {
        const { color: hexColor, value } = step
        return (
          <div key={hexColor} className={classes.legend}>
            <div className={classes.colorKey} style={{ backgroundColor: hexColor }} />
            <span className={classes.colorLabel}>{value}</span>
          </div>
        )
      })}
    </div>
  )
}
