import SearchLayout from './Search/Search'
import './Home.css'
import { ErrorBanner } from '@/components'

const HomeLayout = () => {
  return (
    <div className='home'>
      <SearchLayout />
      <ErrorBanner />
    </div>
  )
}

export default HomeLayout
