import { Box, IconButton, Tooltip } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyles = makeStyles(() => ({
  icon: {
    color: 'white',
    fontSize: '3.3rem',
    cursor: 'pointer'
  },
  backButton: {
    backgroundColor: 'rgb(30, 30, 38)',
    width: '3.3rem',
    height: '3.3rem',
    borderColor: 'white',
    cursor: 'pointer'
  }
}))

/**
 * Shows the back to search button in the header.
 * @component
 */
const BackToSearch = ({ history }) => {
  const base = process.env.PUBLIC_URL || ''
  const classes = useStyles()

  const handleOnClick = () => history.push(`${base}/`)

  return (
    <Tooltip title='Back to Search'>
      <IconButton className={classes.backButton} onClick={handleOnClick} aligncontent='center'>
        <ChevronLeftIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  )
}

export default withRouter(BackToSearch)
