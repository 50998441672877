import { MuiThemeProvider } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'
import { useState, useRef } from 'react'

import { debounceSearchRender } from './DebounceSearch'
import FilterFooter from './FilterFooter'
import ExpandButton from './ExpandButton'
import TableHead from './TableHead'
import { getMuiTheme } from './theme'

export default function DataTable({
  columns,
  customFilterFooter,
  data,
  disableFilterButton,
  onDataTableChange,
  otherOptions
}) {
  const [filtering, setFiltering] = useState(false)
  const applyFiltersRef = useRef(null)

  // callback for when the user clicks the 'submit' button on the filter footer.
  const applyFilters = () => {
    setFiltering(true)
    applyFiltersRef.current()
  }

  // returns the custom footer for the filters popover. calling applyNewFilters
  // will actually apply the filters to the datatable. parameters comes from
  // customFilterDialogFooter data table option.
  const filterFooter = (_, applyNewFilters) => {
    // set applyNewFilters to a ref so we can call it outside this function
    applyFiltersRef.current = applyNewFilters

    return <FilterFooter filtering={filtering} applyFilters={applyFilters} disabled={disableFilterButton} />
  }

  // sets loading to false because filtering has finished
  const handleFilterClose = () => setFiltering(false)

  // table change callback
  const handleTableChange = (action, tableState) => {
    // call the callback if the user provided it
    if (onDataTableChange) {
      onDataTableChange(action, tableState)
    }
  }

  let comps = otherOptions.components ? { ...otherOptions.components } : {}

  const options = {
    columns,
    confirmFilters: true,
    customSearchRender: debounceSearchRender(500),
    elevation: 0,
    expandableRows: false,
    expandableRowsHeader: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    onTableChange: handleTableChange,
    pagination: true,
    print: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 50],
    selectableRows: 'none',
    tableBodyMaxHeight: '500px',
    viewColumns: false,
    ...otherOptions
  }

  if (customFilterFooter) {
    options.customFilterDialogFooter = filterFooter
    options.onFilterDialogClose = handleFilterClose
  }

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        data={data}
        aria-label='policy table'
        columns={columns}
        options={options}
        components={{
          ...comps,
          ExpandButton: (props) => <ExpandButton {...props} />,
          TableHead: (props) => <TableHead {...props} />
        }}
      />
    </MuiThemeProvider>
  )
}

DataTable.propTypes = {
  columns: PropTypes.array,
  customFilterFooter: PropTypes.bool,
  customRowRender: PropTypes.func,
  data: PropTypes.array,
  disableFilterButton: PropTypes.bool,
  onDataTableChange: PropTypes.func,
  otherOptions: PropTypes.object
}

DataTable.defaultProps = {
  columns: [],
  customFilterFooter: true,
  customRowRender: () => {},
  data: [],
  disableFilterButton: false,
  onDataTableChange: () => {},
  otherOptions: {
    expandableRows: true
  }
}
