import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

/**
 * Wraps a react router route and renders a component
 * in within a specified layout.
 * @component
 */
export default function RouteWrapper({ component: Component, layout: Layout, layoutProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} {...layoutProps}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

RouteWrapper.propTypes = {
  /**
   * Component to render
   */
  component: PropTypes.any.isRequired,
  /**
   * Layout to render the component in
   */
  layout: PropTypes.any,
  /**
   * Props to pass to layout component
   */
  layoutProps: PropTypes.object
}

RouteWrapper.defaultProps = {
  layoutProps: {}
}
