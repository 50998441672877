import { useQuery, gql } from '@apollo/client'
import { isMapFeedOpenVar } from '@/graphql/cache'

const GET_FEED_STATE = gql`
  query MapFeedStatus {
    isMapFeedOpen @client
  }
`

export default () => {
  const { data } = useQuery(GET_FEED_STATE)

  // update
  const update = (val) => isMapFeedOpenVar(val)

  return [data.isMapFeedOpen, update]
}
