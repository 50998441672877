import React from 'react'
import moment from 'moment'
import { BillStatusEnum } from '@/utils/constants'

export function SupporterFeedBill({ item }) {
  const { billNumber, title } = item

  return (
    <>
      {billNumber} <span style={{ color: '#B8B8B8' }}>{title}</span>
    </>
  )
}

export function SupporterFeedBillSubInfo({ item }) {
  const { status, statusDate } = item

  return (
    <>
      {BillStatusEnum[status]} <span style={{ float: 'right' }}>{moment(statusDate).format('MM/DD/YYYY')}</span>
    </>
  )
}
