/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { Box, Typography, ClickAwayListener, Tooltip, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/Info'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: 'transparent',
    color: 'rgb(41,41,53)',
    cursor: 'pointer',
    fontSize: '1.4rem',
    marginBottom: '0px'
  },
  box: theme.infoButtonBox,
  description: {
    padding: theme.spacing(1),
    fontSize: '1rem'
  },
  dimension: {
    fontSize: '1rem',
    fontWeight: 400
  },
  tooltip: {
    background: 'rgba(34, 34, 44)',
    borderStyle: 'solid',
    borderWidth: '1px',
    border: 'rgb(24,24,30)',
    boxShadow: '0 4px 8px 0 rgba(64,64,64, 0.2), 0 6px 20px 0 rgba(64,64,64, 0.19);',
    borderRadius: '2px'
  },
  tooltipContainer: {
    width: '100vw',
    maxWidth: 500,
    marginRight: '30rem'
  },
  iconButton: {
    color: '#fff',
    marginLeft: '5%'
  },
  closeIcon: {
    fontSize: '1rem'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1rem'
  },
  bulletList: {
    textAlign: 'left',
    paddingBottom: '0.5rem'
  }
}))

/**
 * Button that is used to show tooltips to the user whenever someone
 * clicks on it.
 * @return {void}
 */
export default function InfoButton({ cardTitle, infoData }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(!open)
  }

  let description
  let dimension
  let note

  if (infoData && infoData.length !== 0) {
    description = infoData[0].description
    dimension = infoData[0].dimension
    note = infoData[0].note
  }

  const label = `Information about graphic: ${cardTitle}`

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              <div style={{ float: 'right' }}>
                <IconButton className={classes.iconButton} onClick={handleTooltipClose} size='small'>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <Typography className={classes.title}>{cardTitle}</Typography>

              {description !== null ? (
                <div className={classes.description}>
                  {description.map((text, index) => (
                    <Typography key={index} className={classes.description}>
                      {text}
                    </Typography>
                  ))}
                </div>
              ) : null}

              {dimension !== null ? (
                <div className={classes.dimension}>
                  <ul>
                    {dimension.map((bulletItem) => (
                      <li key={bulletItem} className={classes.bulletList}>
                        {bulletItem}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {note !== null ? <Typography className={classes.description}>{note}</Typography> : null}
            </>
          }
          classes={{
            tooltipPlacementBottom: classes.tooltip,
            tooltipPlacementTop: classes.tooltip,
            tooltip: classes.tooltipContainer
          }}
        >
          <div>
            <Tooltip title='Information'>
              <IconButton aria-label={label} onClick={handleTooltipOpen} aligncontent='center'>
                <Box borderRadius='100%' className={classes.box} aligncontent='center'>
                  <InfoOutlinedIcon className={classes.icon} />
                </Box>
              </IconButton>
            </Tooltip>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}
