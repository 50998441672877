import { gql } from '@apollo/client'

const nationalPolicyTableQuery = gql`
  query nationalPolicyTableQuery($tags: JSON!, $startDate: DateScalar!, $endDate: DateScalar!) {
    nationalPolicyTableData(tags: $tags, startDate: $startDate, endDate: $endDate) {
      billId
      billNumber
      billType
      role
      url
      session
      lastActionDate
      description
      status
      topics
    }
  }
`

export { nationalPolicyTableQuery }
