const FeedType = {
  NATIONAL_BILL: 2,
  STATE_BILL: 3
}

const BillStatusEnum = {
  1: 'Introduced',
  2: 'Engrossed',
  3: 'Enrolled',
  4: 'Passed',
  5: 'Vetoed'
}

const PolicyEnum = {
  National: 'NationalPolicy',
  State: 'StatePolicy'
}

const States = [
  'AK',
  'AL',
  'AR',
  // 'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  // 'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
]

const currentYear = new Date().getFullYear()
const StateHeatMapYears = [
  (currentYear - 9).toString(),
  (currentYear - 8).toString(),
  (currentYear - 7).toString(),
  (currentYear - 6).toString(),
  (currentYear - 5).toString(),
  (currentYear - 4).toString(),
  (currentYear - 3).toString(),
  (currentYear - 2).toString(),
  (currentYear - 1).toString(),
  currentYear.toString()
]

const StatesEnum = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
}

const cancerTagValues = [
  'Cancer',
  'Breast',
  'Cervical',
  'Colorectal',
  'Gynecologic',
  'Lung',
  'Prostate',
  'Skin',
  'Uterine',
  'Sdoh'
]

const strategicTagValues = ['Screening', 'Survivor']
const StateTableChambers = ['Upper House', 'Lower House']
const StatePolicyTypes = ['Bills', 'Resolutions']
const MasterTabEnum = {
  NationalPolicy: 0,
  StatePolicy: 1
}

const PolicyGlossary = [
  { title: 'Bill', description: 'A legislative measure that is used to propose a new law.' },
  { title: 'Engrossed', description: 'The proposed legislation was successful in the first chamber.' },
  { title: 'Enrolled', description: 'The proposed legislation was successful in both chambers.' },
  { title: 'Failed', description: 'The proposed legislation did not become a law.' },
  { title: 'Introduced', description: 'The proposed legislation has been created.' },
  { title: 'Legislation', description: 'Any legislative item, such as a bill or resolution.' },
  { title: 'Passed', description: 'The proposed legislation was signed into law.' },
  {
    title: 'Resolution',
    description:
      'A form of legislation that is used to express approval or disapproval of something. Resolutions do not become laws.'
  },
  {
    title: 'Social Determinants of Health',
    description:
      'The conditions in which we are born, live, work, and play. They influence the opportunities available to practice healthy behaviors, enhancing or limiting our ability to live healthy lives. Differences in social determinants of health contribute to health disparities.'
  },
  { title: 'Vetoed', description: 'The President or governor rejected the proposed legislation.' }
]

const chamberOptions = [
  {
    id: 0,
    value: 'All',
    label: 'Both Chambers'
  },
  {
    id: 1,
    value: 'H',
    label: 'House'
  },
  {
    id: 2,
    value: 'S',
    label: 'Senate'
  }
]

const statusOptions = [
  {
    id: 0,
    value: 'All',
    label: 'All Statuses'
  },
  {
    id: 1,
    value: 1,
    label: 'Introduced'
  },
  {
    id: 2,
    value: 2,
    label: 'Engrossed'
  },
  {
    id: 3,
    value: 3,
    label: 'Enrolled'
  },
  {
    id: 4,
    value: 4,
    label: 'Passed'
  }
]

const typeOptions = [
  {
    id: 0,
    value: 'ALL',
    label: 'All Legislation'
  },
  {
    id: 1,
    value: 'B',
    label: 'Bills'
  },
  {
    id: 2,
    value: 'R',
    label: 'Resolutions'
  }
]

export {
  BillStatusEnum,
  cancerTagValues,
  chamberOptions,
  FeedType,
  MasterTabEnum,
  PolicyEnum,
  PolicyGlossary,
  StateHeatMapYears,
  StatePolicyTypes,
  States,
  StatesEnum,
  StateTableChambers,
  statusOptions,
  strategicTagValues,
  typeOptions
}
