import { Box, Grid, makeStyles, createStyles } from '@material-ui/core'

import { FooterContainer, Questions, Information, Connect, Privacy, Disclaimer } from './components'

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.footer.main,
      color: theme.palette.footer.white
    },
    disclaimer: {
      backgroundColor: theme.palette.footer.main,
      color: theme.palette.footer.white
    }
  })
)

/**
 * Displays the CDC Footer
 * @component
 */
export default function Footer({ hidden }) {
  const classes = useStyles()

  return (
    <div style={{ flexShrink: 0 }} role='contentinfo' aria-hidden={hidden}>
      <Box className={classes.footer} py={3}>
        <FooterContainer>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid item>
              <Questions />
            </Grid>
            <Grid item>
              <Information />
            </Grid>
            <Grid item>
              <Privacy />
            </Grid>
            <Grid item>
              <Connect />
            </Grid>
          </Grid>
        </FooterContainer>
      </Box>
      <Box className={classes.disclaimer}>
        <FooterContainer>
          <Disclaimer />
        </FooterContainer>
      </Box>
    </div>
  )
}
