import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { Tutorial, MobilePopup } from '@/components'

import { StateKPI, StateTable, StateMapCard, PolicyByState } from './Cards'
import { SearchResultsHeader } from 'src/views'
import { useMapFeedStatus } from '@/hooks'

import { Typography } from '@material-ui/core'
// import { SearchResultsHeader } from 'src/layouts/IssueMonitor/components'
import { useSearchParams } from '@/hooks'

export default function StateDashboard() {
  const searchParams = useSearchParams()
  const [, updateMapFeedStatus] = useMapFeedStatus()

  useEffect(() => {
    updateMapFeedStatus(false)
  }, [])

  return (
    <div id='state_dashboard' role='main' aria-label='State Dashboard' style={{ marginTop: 20 }}>
      {isMobile ? (
        <MobilePopup />
      ) : (
        <>
          <Tutorial isHomeTut={false} />
          <div>
            <Typography style={{ color: 'white' }} variant='h1'>
              State Legislation
            </Typography>
          </div>
          <div>
            <SearchResultsHeader searchParams={searchParams} />
          </div>
          <StateKPI />
          <StateMapCard />
          <PolicyByState />
          <StateTable />
        </>
      )}
    </div>
  )
}
