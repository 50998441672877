import { useQuery, gql } from '@apollo/client'
import { selectedKPIbodyVar } from '@/graphql/cache'

const GET_SELECTED_KPI = gql`
  query CurrentKPI {
    selectedKPIbody @client
  }
`

export default () => {
  const { data } = useQuery(GET_SELECTED_KPI)

  // update
  const update = (val) => selectedKPIbodyVar(val)

  return [data.selectedKPIbody, update]
}
