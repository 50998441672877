import { gql } from '@apollo/client'

const nationalPolicyItemsQuery = gql`
  query nationalPolicyItemsQuery($tags: JSON!, $endDate: DateScalar!, $startDate: DateScalar!) {
    nationalPolicyItemsOverTime(tags: $tags, endDate: $endDate, startDate: $startDate) {
      count
      status
      billType
      body
      year
      week
      statusDate
    }
  }
`

export { nationalPolicyItemsQuery }
