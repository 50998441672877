import { useState } from 'react'
import { Grid, Typography, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLazyQuery, useQuery } from '@apollo/client'

import { isEmpty, omit } from 'lodash'
import { Card, SimpleSearch, LoadingSpinner, ErrorMessage, DataUnavailable, KPI } from '@/components'
import { useSearchParams } from '@/hooks'
import { billsFeedQuery, supporterDataQuery } from '@/graphql/national/'
import { TopSupportersList, SupporterFeed, SupportersKpi } from './components'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'
import { PolicyEnum } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({
  filtertitle: theme.datafilter
}))

/**
 * Top Supporter Card
 * Show casing members of Congress supporting these topics.
 * @component
 */
export default function TopSupporters() {
  const [membersList, setMembersList] = useState(null)
  const [selectedBillMember, setSelectedBillMember] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const [policyCount, setPolicyCount] = useState(null)
  const [congressBillStatus, setCongressBillStatus] = useState(null)
  const [congressBillCount, setCongressBillCount] = useState({ total: 0, rep: 0, sen: 0 })
  const [billsCMData, setBillsCMData] = useState(null)
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  const [downloadData, setDownloadData] = useState([])

  const classes = useStyles()

  // Variables for useQuery
  const searchParams = useSearchParams()
  const { tags, startDate, endDate } = searchParams
  const policyType = PolicyEnum.National

  // String Constants
  const memberText = ['Select a member of Congress to view their policy items']

  const infoData = [
    {
      title: 'Who is sponsoring these legislative items?',
      description: [
        'This section shows the members of Congress who are sponsoring legislative items that match your search choices. Click on a Congress member’s name to see a list of the legislative items they are sponsoring. Congress members with a green badge near their name are part of an appropriations or health committee.'
      ],
      dimension: null,
      note: null
    }
  ]

  const [getBillsFeed, { loading: billLoading, data: billData }] = useLazyQuery(billsFeedQuery)

  const { loading, error } = useQuery(supporterDataQuery, {
    variables: {
      tags,
      startDate,
      endDate,
      policyType
    },
    onCompleted: ({
      topLevelNationalPolicyKpisData: npKPIdata,
      topLevelBillKpisData: bKPIdata,
      billsByCongressMemberData: bCMData
    }) => {
      setIsDataEmpty(isEmpty(bCMData) || isEmpty(bKPIdata.congressBillStatus))
      setPolicyCount(npKPIdata.policyCount)
      setCongressBillStatus(bKPIdata.congressBillStatus)
      setBillsCMData(bCMData)
      setMembersList(bCMData)

      let csvData = [],
        rep = 0,
        sen = 0

      // get the count of distribution of senators
      // and representatives supporting
      for (const x of bCMData) {
        let chamber = ''

        if (x.type === 'rep') {
          chamber = 'House'
          rep += 1
        } else {
          chamber = 'Senate'
          sen += 1
        }

        csvData.push({
          ...omit(x, ['status', 'type']),
          Chamber: chamber
        })
      }

      setCongressBillCount({
        total: bKPIdata.congressBillCount,
        rep,
        sen
      })

      if (bCMData.length > 0) {
        const member = bCMData[0]
        setSelectedBillMember(member.opensecretsId)
        getBillsFeed({ variables: { opensecretsId: member.opensecretsId, tags, startDate, endDate } })
      }

      setDownloadData([...csvData])
    }
  })

  /**
   * Handle selection function that setsSelectedBill Member
   * and called getBillsFeed lazy query
   * @constructor
   * @param {Number} opensecretsid -id for selected bill member
   */
  const handleSelection = (s, opensecretsId) => {
    setSelectedBillMember(opensecretsId)
    getBillsFeed({ variables: { opensecretsId, tags, startDate, endDate } })
  }

  /**
   * Selection reset function.
   * @function
   */
  const resetSelected = () => {
    setSelectedBillMember(null)
    setInputValue('')
    setMembersList(billsCMData)
  }

  /**
   * Handle search function that sets InputValue
   * and checks if searchVal and selectedTab exists.
   */
  const handleSearch = (e) => {
    const searchValue = e.target.value

    setMembersList(
      searchValue === ''
        ? billsCMData
        : billsCMData.filter((o) => o.fullName.toLowerCase().includes(searchValue.toLowerCase()))
    )
    setInputValue(searchValue)
  }

  /**
   * Input reset function.
   * @function
   */
  const resetInput = () => {
    setInputValue('')
    setMembersList(billsCMData)
  }

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={error || loading || isDataEmpty}
      fileName='TopSupporters'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {!isDataEmpty && billsCMData && membersList && (
        <>
          <KPI>
            <SupportersKpi
              congressCount={congressBillCount}
              data={billsCMData}
              totals={policyCount}
              selectedTab={false}
              congressBillStatus={congressBillStatus}
              resetSelected={resetSelected}
            />
          </KPI>
          <Grid item container style={{ flex: '1 1 65%', padding: 20 }}>
            <Grid item container style={{ flex: '1 1 50%', flexDirection: 'column' }}>
              <Grid item container style={{ flex: 1, alignItems: 'center', marginBottom: '1.2rem' }}>
                <Typography classes={{ h5: classes.filtertitle, root: classes.margintitle }}>TOP MEMBERS</Typography>
                <InputLabel
                  style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 0,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    width: 0
                  }}
                >
                  Top Members Search
                </InputLabel>
                <SimpleSearch
                  ariaLabel='Top Members Search'
                  onChange={(e) => handleSearch(e, faBullseye)}
                  placeholder='Search...'
                  value={inputValue}
                  reset={resetInput}
                />
              </Grid>
              <Grid item style={{ flex: 1, paddingRight: 15 }}>
                <TopSupportersList data={membersList} onSelection={handleSelection} selected={{ selectedBillMember }} />
              </Grid>
            </Grid>
            <Grid item style={{ flex: '1 1 50%' }}>
              <SupporterFeed
                cardText={memberText[1]}
                feedData={selectedBillMember ? billData : null}
                loading={billLoading}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  )
}
