import { useQuery, gql } from '@apollo/client'
import { senateHouseVar } from '@/graphql/cache'

const GET_SENATEHOUSE_TOGGLE = gql`
  query CurrentToggleValue {
    senateHouseToggle @client
  }
`

export default () => {
  const { data } = useQuery(GET_SENATEHOUSE_TOGGLE)

  // update
  const update = (val) => senateHouseVar(val)

  return [data.senateHouseToggle || 'sen', update]
}
