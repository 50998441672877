import { Grid, Link, Typography } from '@material-ui/core'

/**
 * Component to show the Privacy section in the CDC Footer
 * @component
 */
export default function Privacy() {
  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Link color='inherit' href='https://www.cdc.gov/Other/privacy.htmls' rel='noopener noreferrer' target='_blank'>
          <Typography variant='body2'>Privacy</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color='inherit' href='https://www.cdc.gov/od/foia/' rel='noopener noreferrer' target='_blank'>
          <Typography variant='body2'>FOIA</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link
          color='inherit'
          href='https://www.cdc.gov/eeo/nofearact/index.htm'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Typography variant='body2'>No Fear Act</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color='inherit' href='https://oig.hhs.gov/' rel='noopener noreferrer' target='_blank'>
          <Typography variant='body2'>OIG</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link
          color='inherit'
          href='https://www.cdc.gov/other/nondiscrimination.html'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Typography variant='body2'>Nondiscrimination</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link
          color='inherit'
          href='https://www.cdc.gov/contact/accessibility.html'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Typography variant='body2'>Accessibility</Typography>
        </Link>
      </Grid>
    </Grid>
  )
}
