import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClinicMedical } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  avatartooltip: theme.avatartooltip,
  description: theme.tooltipdescription,
  link: theme.tooltiplink,
  committee: {
    fontStyle: 'italic'
  }
}))

/**
 * Component is used for tooltips for Badge
 * Avatar.
 * @component
 */
export default function BadgeTooltip({ data }) {
  const { committee, fullName, govtrackUrl } = data
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.description}>
        <b>{fullName}</b>
      </Typography>
      {committee !== null ? (
        <Typography align='left' classes={{ root: classes.description, alignLeft: classes.committee }}>
          <FontAwesomeIcon color='RGB(75, 227, 172)' icon={faClinicMedical} /> {committee}
        </Typography>
      ) : (
        ''
      )}

      <Typography align='center' className={classes.description}>
        <Link className={classes.link} href={govtrackUrl} target='_blank' rel='noopener'>
          GovTrack Profile
        </Link>
      </Typography>
    </>
  )
}

BadgeTooltip.propTypes = {
  /**
   * Data object being passed into toolTip
   */
  data: PropTypes.object
}

BadgeTooltip.defaultProps = {
  data: {}
}
