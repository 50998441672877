import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { BackToSearch, Glossary, HelpButton } from '@/components'

const useStyles = makeStyles({
  buttonDiv: {
    display: 'flex'
  }
})

/**
 * Shows the glossary, tutorial and back button
 * @component
 */
export default function LayoutButtons() {
  const classes = useStyles()

  return (
    <Grid container alignItems='center' justifyContent='space-between' role='navigation' aria-label='Top Navigation'>
      <Grid item id='backToSearch'>
        <BackToSearch />
      </Grid>
      <Grid item>
        <div className={classes.buttonDiv}>
          <HelpButton isHomeTut={false} />
          <Glossary className={classes.glossaryIcon} />
        </div>
      </Grid>
    </Grid>
  )
}

// LayoutButtons.propTypes = {
//   /**
//    * List of selected terms and dates from the landing page
//    */
//   searchParams: PropTypes.object.isRequired
// }
